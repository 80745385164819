.signup-screen2-container{
    padding:0;
    margin:0;
    display: flex;
    flex-direction: row;
}

.signup-screen2-art{
    min-height: 100vh;
  	height: inherit;
    background:#F3FCF7;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width:50vw;
    min-width:50vw;
}


/* NOTE: signup screen2 form */
.signup-screen2-form{
    width:50vw;
    display: flex;
    flex-direction: column;
    position: relative;
}

.signup-screen2-form > .title{
    margin-left:17.5%;
    margin-right:auto;
    margin-top:12%;
    margin-bottom:8%;
    text-align:left ;
    width:65%;
    min-width:250px;
}

.signup-screen2-form > form{
    margin-left:17.5%;
    margin-right:17.5%;
    display: flex;
    flex-direction: column;
    min-width: 338px;
}

.signup-screen2-form > form > .sub-title{
    margin-bottom:24px;
}

.signup-screen2-form > form > .fields{
    margin-bottom:4px;
    display:flex;
    flex-direction: row;
}

.signup-screen2-form > form > hr{
    margin-bottom:24px;
}

.signup-screen2-form > form > .fields > input[type='text']{
    width:40px;
    height:40px;
    margin-right:12px;
    background: #FFFFFF;
    border: 1px solid #e1e5e2;
    border-radius: 3px;
    text-align: center;
}
.signup-screen2-form > form > .fields > input[type='text']:focus{
    border:1px solid #34cb65 !important;
}
.signup-screen2-form > form > .send-again{
    cursor:pointer;
}

.signup-screen2-form > form > hr{
    background-color:#e1e5e2;
    height:1px;
    border:0;
    /* border: 1px solid #C5C5C5; */
}

/* .signup-screen2-form > form > input[type='submit']{
    width:141px;
    align-self: flex-end;
} */

/* .signup-screen2-form > form > .buttons-container > button:nth-child(1){
    background:url('../../assets/signup-screen3-back-arrow.svg') no-repeat 20px 15px;
    color:#2D2D2D;
    padding:10px 30px 10px 40px;
    border: 1px solid #EBEBEB;
    border-radius: 3px;
} */
@media only screen and (max-width: 600px) {

    .signup-screen2-form {
  margin-top: 64px;
  width: 60vw !important;
    }
    hr{
        width: 300px
    }
.btnscreen2 {
        position: fixed;
        bottom: 24px;
        width: 300px;
    }
    .btns2{
        width: 300px;
    }
}