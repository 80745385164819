#block-list-modal-body {
	position: relative;
	height: max-content;
	max-height: 70vh;
	padding: 24px;
	row-gap: 12px;
	flex-direction: column;
	
	display: flex;
	flex-direction: column;
	& .block-list-modal-body-item {
		align-self: stretch;
		display: flex;
		flex-direction: column;
		row-gap: 12px;

		/* search contact input*/
		&:first-child {
			height: 36px;
			min-height: 36px;
			padding-right: 8px;
			border-radius: 6px;
			border: none;
			padding-left: 12px;
			transition: outline 0.1s ease-in;
			color: var(--grey-700);
			&::placeholder {
				color: #B3B9C6;
			}
			&:focus {
				outline: 1.5px solid var(--green-500);
			}
		}

		/* block-search and body */
		&.main-body {
			padding-block: 16px;
			max-height: 350px;
			position: relative;
			padding-bottom: 0;
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				padding-top: inherit;
				padding-bottom: 12px;
				box-sizing: content-box;
				height: 36px;
				top: 0;
				left: 0;
				border-bottom: 1px solid #E1E5E2;
			}
		}
		&.border {
			outline: 1px solid var(--grey-200);
			border-radius: 6px;
		}

		& .search-contact-container {
			padding-inline: 16px;
			& .search-contact {
				padding-left: 24px;
				padding-right: 8px;
				outline: 1px solid var(--grey-200);
				border: none;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding-left: 12px;
				column-gap: 8px;
				border-radius: 6px;
				max-width: 320px;
				transition: outline 0.1s ease-in;
				
				&:focus-within {
					outline: 1.5px solid var(--green-500);
				}
				& > input {
					outline: none;
					border: none;
					height: 36px;
					color: var(--grey-700);
					position: relative;
					z-index: 1;
					&::placeholder {
						color: #B3B9C6;
					}
					flex: 1;
					border-radius: inherit;
				}
			}
		}

		& .blocked-contacts-body {
			border-bottom: 1px solid var(--gray-50);
			overflow-y: auto;
			overflow-x: hidden;
			/* 4 rows each of 64px height with 4th row cut-off by 1px when scrollY==0 */
			max-height: calc(4 * 64px - 1px);
			&::-webkit-scrollbar {
				width: 8px;
				border-radius: 8px;
			}
			&::-webkit-scrollbar-track {
				background: #fff,
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 8px;
				background: #E4E4E7;
			}
			&:last-child {
				border-bottom: none;
			}

			& p.no-search-result {
				min-height: 64px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 12px;
			}
		}
	}

	input::-webkit-search-cancel-button {
		display: none;
	}
}

.block-list-container {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-self: stretch;
	position: relative;
	padding-block: 12px;
	cursor: default;
	padding-inline: 16px;
	& .block-list-block {
		height: 40px;
		
		position: relative;
		&:nth-child(2) {
			flex: 1;
		}
		&:last-child {
			align-self: center;
			justify-self: stretch;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
		}
	}
	& .info-container{
		display: flex;
		flex-direction: column;
		align-items: start;

		& .info-block {
			flex: 1;
			color: var(--grey-500);
			&:first-child {
				color: var(--grey-700);
			}
			user-select: all;
		}
	}
	
	&::after {
		content: "";
        position: absolute;
        height: 100%;
        top: 0;
        left: -16px;
        left: 0px;
        bottom: 0;
        right: 0px;
        border-bottom: 1px solid var(--grey-100);
	}
	&:last-child::after {
		display: none;
	}
}