.integrations-container {
    /* font-family: 'Source Sans Pro', sans-serif; */
}

.crm-integrations > .title, .calendar-integrations > .title {
    
    font-weight: 500;
    color: #464D48;
    margin: 32px 0px 32px 0px;
}

.crm-integrations > .content,
.calendar-integrations > .content {
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fill, 290px);
  grid-row-gap: 23px;
  width: calc(100vw - var(--side-nav-width) - 48px);
}

.integration-card {
  width: 268px;
  height: 182px;
  padding: 18px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.integration-card > .upper-half {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  width: 50px; 
  height: 50px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  background-color: #ffffff; 
  overflow: hidden; 
}

.integration-card > .upper-half img {
  width: 30px;
  height: 30px; 
  object-fit: contain; 
}

.integration-card > .lower-half > .title {
    color: #464D48;
    margin: 16px 0px 16px 0px;
}



.zoho-img {
  width: 100px;
  height: 50px;
}

.monday-img {
  width: 76px;
  height: 47px;
}

.monday-img{    
    /* height: 47px;
    width: 76px; */
    height: 40px;
    width: 60px;
}   

.integration-btn > .disconnect-btn {
    background-color: #ffffff;
    border: 1px solid #DDDDDD;
    color: #464D48;
}
.integration-btn > .disconnect-btn:hover {
  background-color: #ffffff;
  border: 1px solid #DDDDDD;
  color: #464D48;
}
.integrations-container .content {
  margin-bottom: 40px;
}


.integration-btn > button {
  height: 36px;
  width: 85px;
  background: #ffffff;
  border: 1px solid #34cb65;
  box-shadow: none;
  text-transform: none;
  font-weight: 400;
  color: #34cb65;
}
.integration-btn > button:hover {
  background: #ffffff;
  border: 1px solid #34cb65;
  box-shadow: none;
  color: #34cb65;
}
.integration-btn {
  display: flex;
}
.integration-btn > .install-btn {
  font-size: 14px;
  background: #ffffff;
  border: 1px solid white;
  box-shadow: none;
  text-transform: none;
  font-weight: 500;
  color: #219848;
  margin-left: auto; 
}
.integration-btn > .install-btn:hover{
  border: 1px solid white;
  box-shadow: none;
  color: #219848;
}
.lower-half > .title{
  color: #3F3F46 !important;
  font-size: 16px;
  font-weight: 500;
}