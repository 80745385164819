.toggle-feature {
  display: flex;
  justify-content: space-between;
  border: 1px solid #DEDEDE;
  padding:20px;
  margin-right:32px;
  border-radius:5px 5px 0 0;
}

/* tooltip for disabled options in product page */

.toggle-feature  .coming-soon-tooltip {
  user-select: none;
  opacity:0;
  transition: all 0.3s ease-in-out;
}
.toggle-feature:hover  .coming-soon-tooltip {
  opacity:1;
}

.toggle-feature:nth-child(2n+1){
  border-radius: 0 0 5px 5px;
}

.toggle-feature:nth-child(2n){
  border-bottom:none;
}

.toggle-feature .title {
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
  background-color: #34cb65;
}

input:focus + .slider {
  box-shadow: 0 0 1px #34cb65;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}