/* TODO: Need to set body padding to 0 from 100+ which is being set from\&for call-logs */
.login-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: static;
  z-index: 6;
}

.login-art {
  min-height: 100vh;
  height: inherit;
  background: #f3fcf7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 50vw;
  min-width: 50vw;
  overflow: hidden;
}

.login-art > a{
  transform: scale(0.85);
}

/* LOGO */
.login-art > a {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 40px;
  margin-left: 40px;
}

/* TOP DOTS */
.login-art > img:nth-child(2) {
  position: absolute;
  right: 0;
  top: 0;
}

/* MAIN VECTOR IMAGE WITH HUMAN */
.login-art > img:nth-child(3) {
  margin: auto;
  width: 60vmin;
  transform: translateY(-55px);
}

/* BOTTOM DOTS */
.login-art > img:nth-child(4) {
  position: absolute;
  bottom: 0;
  left: 0;
}

/*  */
.login-art > p:last-child {
  position: absolute;
  top: 65%;
  margin-top: 4px;
}

/* NOTE: Login form */
.login-form {
  width: 50vw;
  display: flex;
  flex-direction: column;
}

.login-form > .title {
  padding-left: 0;
  padding-right: 0;
  margin-top: 15%;
  margin-bottom: 15%;
  text-align: center;
  margin-right: 17.5%;
  margin-left: 17.5%;
}

.login-form > form {
  margin-left: 17.5%;
  margin-right: 17.5%;
  display: flex;
  flex-direction: column;
}

.login-form > form > label {
  display: flex;
  flex-direction: column;
}

.login-form > form > label > input {
  margin-top: 8px;
  margin-bottom: 24px;
  height: 33px;
  border-radius: 4px;
  outline: none;
  padding: 12px 8px;
}

.login-form > form > label > input:focus {
  outline: 1px solid #34cb65;
}

.to-forgot-password-container {
  position: relative;
  height: 24px;
  margin-bottom: 56px;
}

.to-forgot-password {
  color: #01be3f;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  width: fit-content;
  right: 0;
}

.to-sign-up-container {
  margin-left: 17.5%;
  margin-right: 17.5%;
  text-align: center;
  margin-top: 32px;
}

.to-sign-up {
  color: #01be3f;
  margin-left: 8px;
  cursor: pointer;
  text-decoration: none;
}
.to-sign-up:hover{
  color: #2EB258 !important;
  text-decoration: underline !important;

}
