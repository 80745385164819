.summaryContainer {
  div {
    border: 1px solid var(--grey-200);
    border-radius: 6px;
    padding: 24px 20px;
    margin-bottom: 16px;

    h6 {
      margin-bottom: 20px;
      color: var(--black);
    }
    p {
      color: var(--grey-700);
    }
    .questions,
    .questions div {
      border: none;
      padding: 0;
      margin-bottom: 0;
    }
    .questions div:first-of-type {
      margin-bottom: 16px;
    }
    p + p {
      margin-top: 4px;
    }
  }
}
