@import "@yaireo/tagify/dist/tagify.css";
/* .suggestion-list > div {
  border: 1px solid #e1e6e2 !important;
} */

.tagify__dropdown__wrapper{
	--tagify-dd-color-primary: #CAFCDA !important;
	border: none;
}

.drop-down {
  z-index: 1302;
  position: fixed !important;
  right: 0 !important;
  bottom: 0 !important;
  top: calc(50vh + 170px) !important;
  left: calc(50vw) !important;
  max-width: 240px !important;
  height: 192px;
  overflow-y: scroll !important;
  border-radius: 4px;
  border: 1px solid #e1e6e2 !important;
}

.drop-down-item {
  border-left-color: #e1e6e2 !important;
  border-right-color: #e1e6e2 !important;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter";
  padding: 12px 20px;
  font-weight: 400;
  color: #464d48;
}
.drop-down-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: #464d48;
  cursor: pointer;
}
.tags-input {
  width: 100%;
  border-bottom: 1px solid #e1e5e2;
  border-radius: 4px 4px 0 0;
  transition: .25s cubic-bezier(0,1,.5,1);
}
.tagify {
  border-bottom-style: none;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
  overflow-y: scroll;
}
.tagify--focus{
  border: 1px solid #34cb65;
  box-shadow: 0px 0px 0px 4px #f0fcf4;
  border-radius: 4px 4px 4px 4px !important;
}
.myTags {
  height: 230px;
  --tag-bg: #f5f7f6;
  --tag-hover: #f5f7f6;
  --tag-text-color: #464d48;
  --tags-border-color: #e1e6e2;
  --tag-remove-btn-bg: #f5f7f6;
  --tag-remove-bg: #f5f7f6;
  --tag-remove-btn-bg--hover: #464d48;
  --input-color: #464d48;
  --tag-pad: 6px 10px;
  /* --tags-focus-border-color: #34cb65; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 500px;
  border-radius: 4px 4px 0 0;
}
.tagify__input .tagify__tag {
  margin: 2px 8px;
}
.msg-template__textarea-container {
  height: 280px;
  border: 1px solid;
  width: 480px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.msg-template__form .textarea-input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 8px 12px;
}
.msg-template__form .msg-template__input {
  border: none !important;
  outline: none;
  width: max-content;
  display: inline;
}
.msg-template__form .msg-template__input:focus {
  border: none;
  box-shadow: none !important;
}
.msg-template__form {
  display: flex;

  justify-content: center;
  flex-direction: column;
}
.msg-template__form label {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.msg-template__form label:last-child {
  margin-top: 20px;
}
.msg-template__form input {
  padding: 8px 7px 8px 12px;
  border: 1px solid #e1e6e2;
  width: 440px;
  border-radius: 4px;
}
.msg-template__form input:focus {
  border: 1px solid #34cb65;
  /* Focused/Green */

  box-shadow: 0px 0px 0px 4px #f0fcf4;
}
.template-modal__btn-container {
  display: flex;
  justify-content: flex-end;
  border: 1px solid #e1e6e2;
  border-top: none;
  border-radius: 0 0 4px 4px;
  /* pull container out of regular document flow */
  position: relative;
  z-index: 2;
}
#insert-variable-button {
  background-color: #fff;
  padding: 2px 12px;
  width: max-content;
}
/* scrollbar @overrides for dropdown*/
.drop-down::-webkit-scrollbar {
  background: #f7faf8;
  width: 8px;
  opacity: 0;
  border-radius: 3px;
}

.drop-down::-webkit-scrollbar-track-piece {
  background: transparent;
}

.drop-down::-webkit-scrollbar-thumb {
  /* Neutral Colors/Grey - 300 */
  background-color: #c6ccc8;
  width: 4px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.drop-down::-webkit-scrollbar-track {
  background: transparent;
  width: 4px;
}

.drop-down::-webkit-scrollbar-corner,
.drop-down::-webkit-scrollbar-button {
  display: none;
}
/* --scrollbar end */

/* scrollbar @overrides for tagify input*/
.tagify::-webkit-scrollbar {
  background: #f7faf8;
  width: 8px;
  opacity: 0;
  border-radius: 3px;
}

.tagify::-webkit-scrollbar-track-piece {
  background: transparent;
}

.tagify::-webkit-scrollbar-thumb {
  /* Neutral Colors/Grey - 300 */
  background-color: #c6ccc8;
  width: 4px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.tagify::-webkit-scrollbar-track {
  background: transparent;
  width: 4px;
}

.tagify::-webkit-scrollbar-corner,
.tagify::-webkit-scrollbar-button {
  display: none;
}
/* --scrollbar end */

.empty {
	padding: 8px 12px;
}

.suggestion-list{
	height: 144px;
  margin-top: 8px;
  margin-left: -16px; /* change negative margin*/
}

/* override message textarea styles */
.tagify--mix .tagify__input {
	padding: 6px 12px;
}
/* overriding tag's internall styles, for visbile cursor
.tagify__tag > div {
	--tag-pad: 2px 4px;
} */

.gmodal-foreground {
	/* padding-bottom: 0px !important;
	margin-bottom: 24px; */
}
.msg-template__form .trigger-container{
  border-top: 1px solid #e1e5e2;
  padding: 24px 0;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}
.msg-template__form .trigger-container > p{
  margin-bottom: 12px;
  width: 100%;
}
.trigger-container .trigger{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e5e2;
  border-radius: 4px;
  padding: 16px;
  gap: 16px;
  margin: 10px 0;
}
.trigger .select-container{
  width: 170px;
}
.trigger-container .delete-icon {
  border: 1px solid #e1e5e2;
  border-radius: 4px;
  padding: 2px 0 0 2px;
  height: 32px;
  width: 32px;
}