.viewCreditBtnContainer{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.viewCreditBtnContainer button{
    display: none;
    background-color: #fff;
    border: 1px solid #e1e5e2;
    border-radius: 4px;
    padding: 8px 12px;
}
.viewCreditBtnContainer button:hover{
    background-color: #f7faf8;
}
.viewCreditBtnContainer:hover button{
    display: inline-block;
}
.headerClass{
    padding-left: 24px !important;
    font-size: 14px;
    color: #464d48;
}