.container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.column{
    flex-basis: 33%;
    flex-grow: 1;
    border-right: 1px solid #e1e5e2;
    padding: 32px 24px;
    height: 508px;
    overflow-y: scroll;
}
.column:last-child{
    border-right: none;
}
.column:last-child h2{
    margin-bottom: 32px;
}
.column:nth-child(2){
    background-color: #fafafa;
}
.stepContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.step{
    width: 256px;
    height: 62px;
    border: 1px solid #e1e5e2;
    border-radius: 8px;
    background-color: #fff;
    padding: 20px 8px;
    position: relative;
    cursor: pointer;
}
.activeStep{
    border: 1px solid #2eb258;
    background-color: #f0fcf4;
}
.stepError{
    border: 1px solid #e0544c
}
.verticalLineLong{
    width: 1px;
    height: 40px;
    border: 1px solid #e1e5e2;
}
.verticalLine{
    width: 1px;
    height: 8px;
    border: 1px solid #e1e5e2;
}
.addStepBtn{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1.5px solid #2eb258;
    padding: 8px;
}
.addStepBtn:hover{
    background-color: #f7faf8;
    cursor: pointer;
}
.threeDotsIcon{
    bottom: 20px;
    position: absolute;
    transition: all 0.3s ease-out;
    right: 8px; 
}
.threeDotsIcon > div{
    opacity: 0;
    width: 28px;
    height: 28px;
    /* padding: 6px; */
    border-radius: 4px;
    border: 1px solid #e1e5e2;
    transition: all 0.3s ease-in;
    pointer-events: none;
    cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.threeDotsIcon:hover > div{
    opacity: 1;
    pointer-events: all;
    background-color: #f7faf8;
}
.activeStep .threeDotsIcon:hover > div{
    background-color: #fff;
}
.quesDescContainer{
    border: 1px solid #e1e5e2;
    border-radius: 4px;
    padding: 8px 12px;
    margin-top: 4px;
    margin-bottom: 20px;
}
.quesDescContainer span:first-child{
    display: inline-block;
    margin-right: 24px;
}
.keyContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 12px;
}
.key{
    border-radius: 4px;
    padding: 8px 12px;
    border: 1px solid #e1e5e2;
    width: 72px;
    height: 36px;
    text-align: center;
}
.errMessage{
    color: #cc4c45;
}
.column::-webkit-scrollbar{
    width: 0;
}