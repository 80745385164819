:root {
	/* Neutral Colors/Grey - 400 */
	/* away:  #C6CCC8 */
	/* offline: transparent */
	/* online: ic-green-300 #34CB65 */
	/* busy:  ic-red-300 #E0544C*/
	--topnav-user-profile-active-bubble-bg: #34cb65;
  }
  
  .top-nav-link {
	cursor: pointer;
	text-decoration: none;
  }
  
  .top-nav-sep {
	width: 1px;
	height: 40px;
	background: #dddddd;
  }
  
  .dropdown {
	position: absolute;
	top: calc(var(--top-nav-height) - 10px);
	width: 100px;
	height: 100px;
	background: #ffffff;
	border-radius: 5px 5px 0 0;
	right: 135px;
	min-height: 210px;
	width: 345px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	z-index: 8;
  }
  
  .status_circle {
	display: none;
	position: absolute;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	bottom: 0;
	right: 0;
  }
  
  #dialer-screen-1:focus {
	border: 1px solid #34cb65 !important;
  }
  
  /* new top nav styles */
  .gtop-nav-container {
	  height:var(--top-nav-height);
	  
  }
  .top-nav-container{
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	  position: relative;
	  min-height: 64px;
	  align-items: center;
	  padding: 10px 24px;
	  padding-top: 2px;
	  border-bottom: 1px solid #e1e5e2;
  }
  
  .gtop-nav-container > .top-nav-container .gleft-section {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	justify-content: flex-end;
  }
  
  .gleft-section img {
	margin-top: auto;
	margin-bottom: auto;
	display: block;
	transform: translateY(4px);
  }
  
  .gtop-nav-container > .top-nav-container > .gright-section {
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  padding-top: 11px;
  }
  
  .gright-section > .top-nav-sep {
	margin-right: 24px;
	margin-left: 24px;
  }
  
  /* Interface Colors/Green - 300 */
  /* ⏳ enable for topNav userprofile drdown avatar icon green circle (related to set yourself as away; availability) */
  /* .profile-account-circle:after {
	  content: '';
	  position: absolute;
	  top: 38px;
	  right: 2px;
	  width: 10px;
	  height: 10px;
	  background: #34CB65;
	  border-radius: 50%;
  
  } */
  .profile-account-circle {
	border-radius: 50%;
	height: 48px;
	width: 48px;
	margin-right: 16px;
	color: #78807a;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border: 1px solid #e1e5e2;
  }
  .dialer--contact_name_input {
	width: 224px;
	height: 28px;
	border-radius: 4px;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	padding-left: 0;
  }
  
  .dialer--contact_name_input:hover {
	border: 1px solid #e1e5e2 !important;
	padding: 2px 8px;
  }
  .dialer--contact_name_input:focus {
	border: 1px solid #34cb65 !important;
	padding: 2px 8px;
  }
  
  /* Country code dropdown styles */
  .dialer--cc_select {
	font-size: 14px;
	line-height: 20px;
	background-color: white;
	border: 1px solid #e1e5e2;
	border-radius: 4px 0 0 4px;
	/* margin-top: 8px; */
	width: 63px;
	height: 36px;
	padding: 9px 5px;
	/* margin-bottom: 0; */
	text-align: center;
	position: relative;
	color: #464d48;
	display: flex;
	align-items: center;
	justify-content: space-around;
  }
  .dialer--dropdown_arrow {
	/* height: 16px; */
	pointer-events: none;
  }
  .dialer--cc_dropdown_container {
	background-color: #fff;
	width: 281px;
	border: 1px solid #ccc;
	border-radius: 6px;
  
	z-index: 2;
  }
  .dialer--cc_search {
	padding: 4px 0;
	border-bottom: 1px solid #e1e5e2;
	margin: 11px 16px 8px 16px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
  }
  .dialer--cc_search input {
	font-size: 16px;
	line-height: 20px;
	font-family: "Inter", sans-serif;
	border: none;
	outline: none;
	padding-left: 10px;
	width: 100%;
  }
  .dialer--cc_search input::placeholder {
	color: #c6ccc8;
  }
  .dialer--cc_options {
	margin: 0;
	padding-left: 0;
	list-style-type: none;
	border-top: 1px solid #e1e5e2;
	text-align: left;
  
	font-family: "Inter", sans-serif;
	height: fit-content;
	max-height: 140px;
	overflow-y: scroll;
  }
  .status_circle {
	  position: absolute;
	  height: 10px;
	  width: 10px;
	  border-radius: 50%;
	  bottom: 0;
	  right: 0;
	}
  
  .dialer--cc_options li {
	padding: 16px;
	font-size: 14px;
	line-height: 20px;
	color: #464d48;
	font-family: "Inter", sans-serif;
	/* border-radius: 6px; */
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
  }
  .dialer--cc_options li:hover {
	background-color: #f7faf8;
  }
  .dialer--cc_options li div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
  }
  .dialer--cc_name {
	max-width: 168px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
  }
  .dialer--flag_img {
	padding-right: 10px;
	margin-bottom: -2px;
  }
  .dialpad--btn {
	display: flex;
	width: 64px;
	height: 56px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	margin: 6px 15px;
	border-radius: 5px;
	border: 1px solid #e1e5e2;
	background-color: #fff;
  }
  .dialpad--btn:hover {
	background-color: #f7faf8;
  }
  .dialpad--btn p:last-child {
	font-size: 12px;
	font-weight: 300;
	line-height: normal;
	margin: 0;
	min-height: 16px;
  }
  .dialpad--btn p:first-child {
	font-size: 24px;
	font-weight: 400;
	line-height: 28px;
	margin: 0;
  }
  .dialpad--screen {
	height: 64px;
	border-bottom: 1px solid #e1e5e2;
	padding: 20px 16px;
	text-align: center;
	font-size: 20px;
	font-weight: 400;
  }
  .dialer--header {
	height: 60px;
	background-color: #f5f7f6;
	border-bottom: 1px solid #e1e5e2;
	padding: 12px 16px;
	border-radius: 6px 6px 0 0;
  }
  .dialer--header div {
	padding: 8px 12px;
	color: #2eb258;
	text-align: center;
	background-color: #fff;
	border-radius: 0 4px 4px 0;
	border: 1px solid #e1e5e2;
  }
  .dialer--mic_permissions {
	max-height: 180px;
	width: 312px;
	padding: 24px 16px;
  }
  .dialer--mic_permissions p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 24px;
	font-family: "Inter", sans-serif;
  }
  
  .rem-credits-container{
	display: flex;
	align-items: center;
	justify-content: space-between;
  }
  .rem-credits-container > div{
	display: flex;
	align-items: center;
	gap: 12px;
  }
  .rem-credits-container > div > span:last-child{
	border-radius: 4px;
	border: 1px solid #34CB65;
	background:#FFF;
	padding: 4px;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  }
  .nav-trial-container{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f0fcf4;
	padding: 8px 16px;
	height: 52px;
	gap: 16px;
  }
  .nav-trial-container p span:last-child{
	text-decoration: underline;
  }
  .nav-trial-container .icon{
  height: 20px;
  }
  .top-nav-link#dialpad-icon{
	border-radius: 4px;
	border: 1px solid #e1e5e2;
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-right: 12px;
  }
  .top-nav-link#dialpad-icon span{
	padding-left: 4px;
  }
  .top-nav-link#dialpad-icon:hover, .top-nav-link#notification-icon:hover{
	background-color: #F7FAF8;
  }
  .top-nav-link#notification-icon{
	border-radius: 4px;
	padding: 10px;
	padding-bottom: 2px;
  }
  .trigger-container{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	border-top: 1px solid #e1e5e2;
  }
  .flex-container{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 15px;
	gap: 10px;
  }

  /********survey screen styles***********/
  .survey--header{
    padding: 10px 16px;
    border-bottom: 1px solid var(--Neutral-Colors-Grey---300, #E1E5E2);
    width: 100%;
    display: flex;
    gap: 6px;
}
.survey--header button{
    background-color: #FFF;
    border: none;
    outline: none;
    cursor: pointer;
}
.survey--container{
    position: relative;
}
.scrollable-container {
    display: flex;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: 315px;
}
.survey--list{
    padding-left: 0;
    width: 100%;
}
.survey--list li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid var(--Neutral-Colors-Grey---200, #f5f7f6);
    cursor: pointer;
}
.survey--list li:hover{
    background-color: var(--Neutral-Colors-Grey---100, #F7FAF8);
}
.survey--list li p{
    margin: 0;
}
.selected-survey{
    background-color: #f0fcf4;
}
.survey--info{
    position: absolute;
    bottom: 66px;
    left: 0;
    background-color: var(--Neutral-Colors-Grey---100, #F7FAF8);
    padding: 12px 20px;
}
.survey--info p{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: var(--Neutral-Colors-Grey---900, #464d48);
    margin: 0;
}
.survey--info button{
    color: #2eb258;
    border: none;
    outline: none;
    float: right;
    background-color: var(--Neutral-Colors-Grey---100, #F7FAF8);
    width: max-content;
    height: 22px;
    cursor: pointer;
}
.btn-container-wrapper{
    width: 100%;
}
.btn-container {
    padding: 16px;
    background: var(--Neutral-Colors-Grey---100, #F7FAF8);
}
.survey--no-survey-container{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 315px;
	gap: 16px;
}
.survey--no-survey-icon{
	height: 64px;
	width: 64px;
	border-radius: 50px;
	border: 1.5px solid #F4F4F5;
	display: flex;
	align-items: center;
	justify-content: center;
}
.notes--textarea{
	height: 300px;
	width: 280px;
	border: 1px solid #e1e5e2;
	border-radius: 4px;
	padding: 8px 12px;
}
.notes--backicon{
	height: 20px;
	width: 20px;
	cursor: pointer;
}
.add-account-option{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	cursor: not-allowed;
}
.outbound-vn-title{
	line-height: auto;
	width: 100%;
	margin-left: 6px;
	overflow: hidden;
	text-align: left;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}