.msg-template__var {
  border: 1px solid #e1e6e2;
  padding: 6px 8px 6px 10px;
  background-color: #f5f7f6;
  display: inline-block;
  margin: 4px 2px;
}

.injectedNoOverFlowStyleForBlockedElement {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-height: 12px; */
  transition: none;
  height: auto;
}

.message-p-wrapper {
  display: inline-flex;
  align-items: center;
}

.injected-for-only-tagify{
  transform: translateY(3px);
}

.MuiDataGrid-columnHeader[data-field="template_name"] {
	padding-left: 20px !important;
}

.MuiDataGrid-columnHeader[data-field="message"] {
	padding-left: 24px !important;
}
.add-trigger-btn{
  color: #2eb258;
  margin-top: 20px;
  display: block;
  cursor: pointer;
  user-select: none;
}
.add-trigger-btn:hover{
  color: #28994b;
}