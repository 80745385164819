/* ✅ ❗❓ */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  /* --top-nav-height: 4em; */
  --side-nav-width: 219px;
  /* 
 *@overrides rsuite variables*/
  --rs-bg-active: #34cb65 !important;
  --rs-calendar-range-bg: #34cb6530 !important;
  --rs-input-focus-border: #34cb65 !important;
  --rs-btn-link-text: #34cb65 !important;
  --rs-btn-link-hover-text: #28b347 !important;
  --rs-btn-link-active-text: #1e981e !important;
  --rs-state-focus-shadow: 0 0 0 3px #34cb6540 !important;
  --rs-btn-primary-bg: #34cb65 !important;
  --rs-btn-primary-hover-bg: #25bc5b !important;
  --rs-btn-primary-active-bg: #16a846 !important;
  --rs-listbox-option-hover-text: #16a846 !important;
  --rs-listbox-option-hover-bg: #34cb6530 !important;
  --rs-picker-value: #4a4a4a !important;
  --rs-text-primary: #4a4a4a !important;

  --rs-picker-count-bg: #34cb65 !important ;
  --rs-checkbox-checked-bg: #34cb65 !important ;
  --rs-text-active: #34cb65 !important ;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* display:unset; */
  padding-bottom: 34px !important;
  min-height: 100vh;
}

.rs-picker-daterange-panel,
.rs-calendar-table-cell-content > span {
  /* font-family: "Source Sans Pro" !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px;
}

button {
  text-transform: none !important;
}

.normal-font {
  /* font-family: "Source Sans Pro"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.bold-font {
  /* font-family: "Source Sans Pro"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.normal-font-poppins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.bold-font-poppins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.px-12 {
  font-size: 12px;
}

.px-14 {
  font-size: 14px;
}

.px-18 {
  font-size: 18px;
}

.px-20 {
  font-size: 20px;
}

.px-22 {
  font-size: 22px;
}

.px-24 {
  font-size: 24px;
}

.px-28 {
  font-size: 28px;
}

.px-30 {
  font-size: 30px;
}

.color-4a {
  color: #4a4a4a;
}

.color-2d {
  color: #2d2d2d;
}

.color-68 {
  color: #686868;
}

.color-c4 {
  color: #c44444;
}

.color-c5 {
  color: #c5c5c5;
}

.color-green {
  color: #01be3f;
}

.color-3c {
  color: #34cb65;
}

.btn {
  border-radius: 3px;
  outline: none;
  border: none;
  padding: 6px 32px 6px 32px;
  cursor: pointer;
}

.btn-white {
  background: #fff;
  border: 1px solid #dedede;
}

.btn-gray {
  background: #f7f7f7;
  border: 1px solid #dedede !important;
  color: #4a4a4a;
  padding: 6px 24px 6px 24px;
}

.btn-gray:active {
  background: #e7e7e7;
}

.btn-green {
  background: #34cb65;
  color: #fff;
}

.btn-green:active {
  background-color: #34cb6595;
}

.btn-green:active:before {
  background-color: #34cb6595;
}

.btn:disabled {
  color: #9a9a9a;
  background-color: #efefef4d;
  cursor: pointer;
}

.btn-green:disabled {
  background: #ebebeb;
  color: #ffffff;
}

.btn-red {
  background: #d53343;
  color: #fff;
}

.btn-red:active {
  background: #d5334395;
}

textarea {
  resize: none;
  outline: none;
}

input[type="text"]:read-only,
input[type="tel"]:read-only {
  color: #9a9a9a;
  /* border-color: #9a9a9a; */
}

a:disabled {
  color: #9a9a9a;
}

.custom-scrollbar::-webkit-scrollbar{
	background:transparent;
	width:8px;
	opacity:0;
	height: 0;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track-piece{
	background:transparent; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb{
	background-color: transparent;
	width:8px;
	border-radius:3px;
	transition:all 0.3s ease-in-out;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track{
	background:transparent;
	width:8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-corner,
  .custom-scrollbar::-webkit-scrollbar-button{
	display: none;
  }
  
  .custom-scrollbar:hover::-webkit-scrollbar-thumb{
	background: #c6ccc8;
  }

  .no-scrollbar.custom-scrollbar::-webkit-scrollbar{
    width: 0px
  }