.settings-container {
    position: absolute;
    top:var(--top-nav-height);
    left:var(--side-nav-width);
    height: calc(100vh - var(--top-nav-height));
    width:calc(100vw - var(--side-nav-width));
    padding-left:40px;
    padding-top:32px;
    border-top: 1px solid #E7E7E7;
    overflow-x:hidden;
    padding-bottom:40px;
}

.settings-title {
    font-size: 28px;
	line-height: 32px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #464D48;
}

.MuiTabs-indicator {
    background-color: #34CB65 !important;
    height: 3px !important;
}

/* hr changed to div.settings-sep */
.settings-container .settings-sep{
    border-top:1px solid #E7E7E7;
    transform:translateX(-40px);
    width:100vw;
}

.settings-container  .settings-header-sep{
    margin-top: 32px;
}

::placeholder {
    color: #C5C5C5;
    
}

.disabled-button {
    background-color: #EBEBEB !important;
    color: #FFFFFF !important;
}

/* settings overlay */
.settings-overlay{
    position:fixed;
    z-index:6;
    top:0px;
    bottom:0;
    right:0;
    left:0;
    height:100vh;
    width:100vw;
    /* z-index:4; */
    background: rgba(0, 0, 0, 0.4);
}

.general-tab .section, .call-sms-tab .section {
  margin-top: 40px;
}

.general-tab .section label, .call-sms-tab .section label {
  display: block;
  margin-top: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #464d48;
}

.general-tab .section label input, .call-sms-tab .section label input {
  display: block;
  margin-top: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #464d48;
  width: 400px;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #e1e6e2;
  border-radius: 4px;
}

.general-tab .section label input:disabled, .call-sms-tab .section label input:disabled {
  background: #f5f7f6;
  border: 1px solid #e1e6e2;
}

.section-btn {
  margin: 40px 0;
}

.general-tab .settings-sep, .call-sms-tab .settings-sep {
  border-top: 1px solid #e7e7e7;
}

.time-slot-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  width: 224px;
}

.dropdown-section label {
  margin-bottom: 12px;
}

.text-box {
  display: flex;
  justify-content: space-between;
  width: 480px;
  height: 40px;
  border: 1px solid #e1e6e2;
  border-radius: 4px 0 0 4px;
  /* padding: 8px 12px; */
}

.text-box input {
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px;
  border-radius: 4px;
  border: hidden;
}

.api-section .copy-btn {
  padding: 10px 24px;
  gap: 6px;
  width: 100px;
  height: 40px;
  background: #34cb65;
  border-radius: 0px 4px 4px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.api-section .show-hide-btn {
  margin-left: 4px;
  padding: 0 4px;
  background-color: white;
}

.api-key-description {
  margin-top: 12px;
}

.api-key-description a {
  text-decoration: underline;
  color: #2eb258;
}

.disabled-input:disabled {
  background: #f5f7f6;
  border: 1px solid #e1e6e2;
}

.call-sms-tab .general-tab-vn-gtable-height-setter {
  margin-top: 40px;
	height: 100%;
}

.MuiDataGrid-columnHeader[data-field="calls"] {
	padding-left: 20px !important;
}

.section:last-child {
	margin-bottom: 100px;
}
.call-sms-tab .switch-form{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0;
}