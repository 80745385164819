.notesForm {
  label {
    color: var(--grey-700);
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  input,
  textarea {
    border: 1px solid var(--grey-200);
    border-radius: 6px;
    padding: 6px 12px;
    margin-bottom: 20px;
  }
  input::placeholder,
  textarea::placeholder {
    color: var(--grey-400) !important;
    font-weight: 400 !important;
    font-family: "Inter" !important;
  }
  textarea {
    padding: 12px;
  }
  .tagContainer input {
    border: none;
    border-radius: 0;
    padding: 0 !important;
    margin: 0;
  }
}
