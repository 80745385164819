.container{
    padding: 40px 32px;
}
.container > p{
    margin-bottom: 32px;
}
.credits{
    padding-top: 32px;
    color: #464D48;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.container form, .switchForm .inputLabel {
    width: 519px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
}
.container .addCreditForm{
    position: relative;
}
.container .switchForm input{
    height: 40px;
}
.container form .textInput{
    display: block;
    margin-top: 4px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #e1e5e2;
}
.inputgroup{
    position: relative;
    display: flex;
    flex-direction: row;
}
.inputgroup span{
    width: 30px;
    position: absolute;
    left: 10px;
    transform: translateY(84%);
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}
.inputgroup input{
    height: 100%;
    padding-left: 20px;
}
.container .switchForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0;
}
.switchForm .inputLabel{
    margin-left: 60px;
    margin-top: 4px;
}
.editBtn{
    color: #2eb258;
    text-decoration: underline;
    background-color: #fff;
    margin-left: 4px;
}
