/* ✅ ❗❓ */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* new colors */
:root {
  --grey-50: #fafafa;
  --grey-100: #f4f4f5;
  --grey-200: #e4e4e7;
  --grey-400: #a0a0ab;
  --grey-500: #70707b;
  --grey-700: #3f3f46;
  --grey-800: #26272b;
  --green-500: #34cb65;
  --green-600: #219848;
  --black: #000000;
  --white: #ffffff;
}

/* new typography */
.display-2xl {
  font-size: 72px;
  line-height: 90px;
  letter-spacing: -2%;
}
.display-xl {
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -2%;
}
.display-lg {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -2%;
}
.display-md {
  font-size: 34px;
  line-height: 44px;
  letter-spacing: -2%;
}
.display-sm {
  font-size: 30px;
  line-height: 38px;
}
.display-xs {
  font-size: 24px;
  line-height: 32px;
}
.text-xl {
  font-size: 20px;
  line-height: 30px;
}
.text-lg {
  font-size: 18px;
  line-height: 28px;
}
.text-md {
  font-size: 16px;
  line-height: 24px;
}
.text-sm {
  font-size: 14px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 18px;
}

:root {
  /* --top-nav-height: 4em; */
  --side-nav-width: 219px;
  /* 
	*@overrides rsuite variables*/
  --rs-bg-active: #34cb65 !important;
  --rs-calendar-range-bg: #34cb6530 !important;
  --rs-input-focus-border: #34cb65 !important;
  --rs-btn-link-text: #34cb65 !important;
  --rs-btn-link-hover-text: #28b347 !important;
  --rs-btn-link-active-text: #1e981e !important;
  --rs-state-focus-shadow: 0 0 0 3px #34cb6540 !important;
  --rs-btn-primary-bg: #34cb65 !important;
  --rs-btn-primary-hover-bg: #25bc5b !important;
  --rs-btn-primary-active-bg: #16a846 !important;
  --rs-listbox-option-hover-text: #16a846 !important;
  --rs-listbox-option-hover-bg: #34cb6530 !important;
  --rs-picker-value: #464d48 !important;
  --rs-text-primary: #464d48 !important;

  --rs-picker-count-bg: #34cb65 !important ;
  --rs-checkbox-checked-bg: #34cb65 !important ;
  --rs-text-active: #34cb65 !important ;
  /* --rs-text-link-active:; check a:active */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  /* --top-nav-height: 4em; */
  --side-nav-width: 219px;
  /* 
*@overrides rsuite variables*/
  --rs-bg-active: #34cb65 !important;
  --rs-calendar-range-bg: #34cb6530 !important;
  --rs-input-focus-border: #34cb65 !important;
  --rs-btn-link-text: #34cb65 !important;
  --rs-btn-link-hover-text: #28b347 !important;
  --rs-btn-link-active-text: #1e981e !important;
  --rs-state-focus-shadow: 0 0 0 3px #34cb6540 !important;
  --rs-btn-primary-bg: #34cb65 !important;
  --rs-btn-primary-hover-bg: #25bc5b !important;
  --rs-btn-primary-active-bg: #16a846 !important;
  --rs-listbox-option-hover-text: #16a846 !important;
  --rs-listbox-option-hover-bg: #34cb6530 !important;
  --rs-picker-value: #464d48 !important;
  --rs-text-primary: #464d48 !important;

  --rs-picker-count-bg: #34cb65 !important ;
  --rs-checkbox-checked-bg: #34cb65 !important ;
  --rs-text-active: #34cb65 !important ;
}

.voice-modal-calendar .rs-picker-toggle {
  border: 1.5px solid #e1e6e2 !important;
  border-radius: 2px !important;
  height: 40px !important;
  position: relative;
  top: -1px;
  left: -2px;
  width: 102% !important;
  padding-top: 8px !important;
}

/* CALL LOGS */
.gheader-calendar-calendar .rs-picker-daterange-header {
  min-width: 246px;
}

.gheader-calendar-calendar .rs-picker-toolbar {
  min-width: 246px;
}

/* unselected state */
.gheader-calendar-calendar .rs-picker-toggle-value {
  display: inline-block !important;
}

.gheader-calendar-calendar .rs-picker-toggle-textbox {
  padding-right: 0 !important;
  padding-left: 6px !important;
}

.gheader-calendar-calendar .rs-picker-value,
.gheader-calendar-calendar .rs-picker-toggle-value,
.gheader-calendar-calendar .rs-picker-toggle {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 16px !important;
  width: fit-content !important;
}

.gheader-calendar-calendar div.rs-picker-toggle[role="combobox"] {
  padding: 10px 6px !important;
  position: relative !important;
}

.gheader-calendar-calendar .rs-picker-toggle-read-only {
  opacity: 1 !important;
}

.gheader-calendar-calendar .rs-picker-toggle-active,
.gheader-calendar-calendar .rs-picker.rs-picker-focused {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.gheader-calendar-calendar:focus-within {
  box-shadow: var(--rs-state-focus-shadow);
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
}

button {
  text-transform: none !important;
}

.px-12 {
  font-size: 12px;
}

.px-14 {
  font-size: 14px;
}

.px-18 {
  font-size: 18px;
}

.px-20 {
  font-size: 20px;
}

.px-22 {
  font-size: 22px;
}

.px-24 {
  font-size: 24px;
}

.px-28 {
  font-size: 28px;
}

.px-30 {
  font-size: 30px;
}

.color-4a {
  color: #464d48;
}

.color-2d {
  color: #464d48;
}

.color-68 {
  color: #686868;
}

.color-c4 {
  color: #c44444;
}

.color-c5 {
  color: #c5c5c5;
}

.color-green {
  color: #01be3f;
}

.color-3c {
  color: #34cb65;
}

.btn {
  border-radius: 3px;
  outline: none;
  border: none;
  padding: 6px 32px 6px 32px;
  cursor: pointer;
}

.btn-white {
  background: #fff;
  border: 1px solid #dedede;
}

.btn-gray {
  background: #f7f7f7;
  border: 1px solid #dedede !important;
  color: #464d48;
  padding: 6px 24px 6px 24px;
}

.btn-gray:active {
  background: #e7e7e7;
}

.btn-green {
  background: #34cb65;
  color: #fff;
}

.btn-green:active {
  background-color: #34cb6595;
}

.btn-green:active:before {
  background-color: #34cb6595;
}

.btn:disabled {
  color: #9a9a9a;
  background-color: #efefef4d;
  cursor: pointer;
}

.btn-green:disabled {
  background: #ebebeb;
  color: #ffffff;
}

.btn-red {
  background: #d53343;
  color: #fff;
}

.btn-red:active {
  background: #d5334395;
}

textarea {
  resize: none;
  outline: none;
}

input[type="text"]:read-only,
input[type="tel"]:read-only {
  color: #9a9a9a;
  /* border-color: #9a9a9a; */
}

a:disabled {
  color: #9a9a9a;
}

/*---------------------------------------------------------------------*
*------------------------------ DESIGN 3.0 ----------------------------*
*----------------------------------------------------------------------*
*-------------------------------------------Frejun - 3.0 (figma)-------*
*/

/* default text styles */
.t1 {
  font-size: 40px;
  line-height: 48px;
}
.t2 {
  font-size: 32px;
  line-height: 40px;
}

.t3 {
  font-size: 28px;
  line-height: 32px;
}

.t4 {
  font-size: 24px;
  line-height: 28px;
}

.t5 {
  font-size: 20px;
  line-height: 24px;
}

.t6 {
  font-size: 16px;
  line-height: 24px;
}

.t7 {
  font-size: 14px;
  line-height: 20px;
}

.t8 {
  font-size: 12px;
  line-height: 16px;
}
.light-font {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
}

.regular-font {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
}

.medium-font {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
}

.semibold-font {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
}

.bold-font {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
}

/* button text styles */

.b1 {
  font-size: 16px;
  line-height: 16px;
}

.b2 {
  font-size: 14px;
  line-height: 14px;
}

.b3 {
  font-size: 12px;
  line-height: 12px;
}

.b4 {
  font-size: 10px;
  line-height: 10px;
}

/* interface colors */

/* green */
.ic-green-500 {
  color: #28994b;
}

.ic-green-400 {
  color: #2eb358;
}

.ic-green-300 {
  color: #34cb65;
}

.ic-green-200 {
  color: #cafcda;
}

.ic-green-100 {
  color: #f0fcf4;
}

/* red */
.ic-red-500 {
  color: #b2433d;
}

.ic-red-400 {
  color: #cc4c45;
}

.ic-red-300 {
  color: #e0544c;
}

.ic-red-200 {
  color: #fcccca;
}

.ic-red-100 {
  color: #fcf0f0;
}

.ic-white {
  color: "#fff";
}

/* neutral colors */
.nc-gray-1000 {
  color: #2e3330;
}

.nc-gray-900 {
  color: #464d48;
}

.nc-gray-800 {
  color: #5f6661;
}

.nc-gray-700 {
  color: #78807a;
}

.nc-gray-600 {
  color: #919994;
}

.nc-gray-500 {
  color: #abb3ae;
}

.nc-gray-400 {
  color: #c6ccc8;
}

.nc-gray-300 {
  color: #e1e5e2;
}

.nc-gray-200 {
  color: #f5f7f6;
}

.nc-gray-100 {
  color: #f7faf8;
}

/* tag colors */
.tc-green {
  background: #edfcf2;
}

.tc-border-green {
  border-color: #d3f8df;
}

.tc-red {
  background: #fff1f3;
}

.tc-border-red {
  border-color: #ffe4e8;
}

.tc-blue {
  background: #f0f9ff;
}

.tc-border-blue {
  border-color: #e0f2fe;
}

.tc-gray {
  background: #f8fafc;
}

.tc-border-gray {
  border-color: #eef2f6;
}

.tc-deepblue {
  background: #eef4ff;
}

.tc-border-deepblue {
  border-color: #e0eaff;
}

.tc-violet {
  background: #f4f3ff;
}

.tc-border-violet {
  border-color: #ebe9fe;
}

.tc-pink {
  background: #fdf4ff;
}

.tc-border-pink {
  border-color: #fbe8ff;
}

.tc-bluegreen {
  background: #f0fdf9;
}

.tc-border-bluegreen {
  border-color: #ccfbef;
}

.tc-orange {
  background: #fff4ed;
}

.tc-border-orange {
  border-color: #ffe6d5;
}

.tc-yellow {
  background: #fefbe8;
}

.tc-border-yellow {
  border-color: #fef7c3;
}

/* shadows */

.shadow-modal {
  box-shadow: 0px 8px 8px 2px rgba(95, 102, 97, 0.08);
}

.shadow-draggable {
  box-shadow: 0px 0px 8px 4px rgba(95, 102, 97, 0.1);
}

.shadow-dropdown {
  box-shadow: 0px 2px 8px 2px rgba(95, 102, 97, 0.08);
}

.shadow-input-green {
  border: 1px solid #34cb65;
  box-shadow: 0px 0px 0px 4px #f0fcf4;
}

.shadow-input-red {
  border: 1px solid #e0544c;
  box-shadow: 0px 0px 0px 4px #fcf0f0;
}

/* Screens styles */
.screen-container {
  width: calc(100% - var(--side-nav-width));
  /* height: calc(100% - var(--top-nav-height) - 40px); */
  height: fit-content;
  position: absolute;
  /* top: var(--top-nav-height); */
  left: var(--side-nav-width);
  /* padding: 40px 32px 20px 32px; */
  padding: 24px 32px 0px 32px;
  /* 1200px is the breakpoint(viewport width) beyond which 
	filter-labels break into multiple lines */
  min-width: calc(1200px - var(--side-nav-width));
  /* 1350px is the breakpoint(viewport width) beyond which 
	filter-labels break into multiple lines (when there is atleast one filter selected) */
  min-width: calc(1350px - var(--side-nav-width));
  border-top: none;
  &.screen-padding-bottom {
    padding-bottom: 32px;
  }
}

.with-top-border {
  border-top: 1px solid #dedede;
}

.fullscreen-container {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
}

.screen-container-flex {
  display: flex;
}

/* default @overrides */
input {
  outline: none;
}

/* @overrides reminder calls date&time picker */
#rscustom-date-picker {
  position: absolute;
  z-index: 9;
}

/* rsuite override for --rs-text-link-active*/
.side-nav-li a:active,
.side-nav-li a:focus {
  color: #464d48 !important;
}
.rs-picker-menu {
  z-index: 1301 !important;
}

/* hubspot iframe styles */
#hubspot-conversations-inline-parent {
  position: fixed;
  bottom: 96px;
  right: 16px;
  z-index: 10000;
  background-color: #fff;
}
#hubspot-conversations-inline-iframe {
  max-height: 600px;
  width: 380px;
  border-radius: 12px;
  box-shadow:
    0 6px 6px 0 rgba(0, 0, 0, 0.02),
    0 8px 24px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  font-size: 11px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  border: 1px solid #e1e6e2;
  min-height: 70vh;
}
