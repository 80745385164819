.container {
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: var(--grey-50);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px 24px;
}
