.login-screen2-container{
    padding:0;
    margin:0;
    display: flex;
    flex-direction: row;
}

.login-screen2-art{
    min-height: 100vh;
  	height: inherit;
    background:#F3FCF7;
    /* centering the vector art with human in it */
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width:50vw;
    min-width:50vw;
}

/* NOTE: login screen2 form */
.login-screen2-form{
    width:50vw;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
}

.login-screen2-form > .title{
    margin: 8% auto 8% 17.5%;
    text-align:left ;
    width:65%;
    min-width:250px;
}

.login-screen2-form > form{
    margin-left:17.5%;
    margin-right:17.5%;
    display: flex;
    flex-direction: column;
    min-width: 338px;
}

.login-screen2-form > form > .sub-title{
    margin-bottom:24px;
}

.login-screen2-form > form > .fields{
    margin-bottom:32px;
    display:flex;
    flex-direction: row;
}

.login-screen2-form > form > hr{
    margin-bottom:18px;
}

.login-screen2-form > form > .fields > input[type='text']{
    width:40px;
    height:40px;
    margin-right:12px;
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 3px;
    text-align: center;
}

.login-screen2-form > form > .send-again{
    margin-bottom: 64px;
    cursor:pointer;
}

.login-screen2-form > form > .send-again > span{
    margin-left: 24px;
}

.login-screen2-form > form > hr{
    border:0;
    border-top: 1px solid #C5C5C5;
}

.login-screen2-form > form > input[type='submit']{
    width:141px;
    align-self: flex-end;
}