.licenseInfo{
    border: 1px solid #e1e5e2;
    background-color: #f7faf8;
    padding: 12px 16px;
    color: #464c48;
    border-radius: 6px;
}
.modalContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 521px;
}
.warningContainer{
    background-color: #fefbe8;
    display: flex;
    border-radius: 6px;
    padding: 12px 16px;
    color: #464c48;
}
.warningContainer p{
    margin-left: 12px;
}
.modalContainer > form{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;
}
.modalContainer > form li{
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 16px 24px;
}
.tableCol1{
    padding-left: 14px;
}
.tableCol1 p:nth-child(2){
    margin-top: 0;
}
.tableCol2{
    width: 53px;
    color: #D05C60;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-decoration-line: underline;
    background-color: #fff;
    justify-content: flex-end;
    cursor: pointer;
}
.tableCol2:hover{
    color: #b2433d;
}
.headerClass{
    padding-left: 24px !important;
}