.insightContainer {
  border: 1px solid var(--grey-200);
  border-radius: 6px;
  padding: 24px 20px;
  margin-bottom: 16px;

  .headingContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    h6 {
      color: var(--black);
    }
  }

  h2 {
    color: var(--grey-700);
    margin: 24px 0 6px 0;
  }
}

.ratioContainer {
  h6 {
    color: var(--black);
    margin: 24px 0 10px 0;
  }
  .progressContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: var(--grey-700);
    }

    div {
      background-color: var(--grey-200);
      border-radius: 4px;
      width: 238px;
      height: 8px;

      div {
        height: inherit;
        border-radius: inherit;
        background-color: var(--green-500);
      }
    }
  }
}

.infoContainer {
  border: 1px solid var(--grey-200);
  padding: 4px 12px;
  color: var(--grey-700);
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.senSummary {
  color: var(--grey-700);
}

.question {
  display: flex;
  gap: 8px;
  div,
  svg {
    flex-shrink: 0;
  }
  div {
    border: 1px solid var(--grey-200);
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .checked {
    background-color: var(--green-500);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
  p {
    color: var(--grey-700);
    margin-bottom: 12px;
  }
}
