.slider_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #dadada;
    border-radius: 6px;
    background-color: #f5f7f6;
    padding: 2px;
    width: 360px;
    margin-bottom: 16px;
  }
  
  .option {
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    color: #464d48;
    width: max-content !important;
    padding: 8px 24px;
  }
  
  .selected {
    background-color: #fff;
    transition: all 0.3s;
    color: #2eb258;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    
  }
  .priceCardContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px 0;
  }
  .price_card {
    border: 0.5px solid rgb(214, 214, 214);
    border-radius: 10px;
    /* width: 100%; */
    /* width: calc(100% - 32px); */
    margin: 0 12px;
    transition: 0.3s;
    padding: 32px 24px;
    text-align: center;
  }
  .price_card:first-child{
    margin-left: 0;
  }
  .price_card:last-child{
    margin-right: 0;
  }
  
  .price_card h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #464d48;
  }
  
  .price_card h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 24px;
   color: #464d48;
    margin: 0;
    margin-top: 32px;
  }
  
  .price_card p {
    line-height: 24px !important;
  }
  .feature_list_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
   
  }
  .feature_list_item:last-child{
    margin-bottom: 0;
  }
  
  .btn,
  .btnWhite,
  .btn:focus {
    margin-top: 32px;
    width: 67.5%;
    background: #34cb65;
    border: 1px solid #34cb65;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    height: 56px;
    color: white;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
  .btn a {
    text-decoration: none;
    color: #fff;
  }
  .btn:hover {
    background-color: #2eb258;
    border-color: #2eb258 !important;
  }
  .btnWhite,
  .btnWhite:focus {
    background-color: #fff;
    border: 1px solid #dadada;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: #464d48;
    margin-top: 16px;
  }
  .btnWhite:hover {
    background-color: #f7faf8 !important;
    border: 1px solid #e1e5e2 !important;
    color: #464d48 !important;
  }
  .price_card .seperator {
    border: 1px solid #e5e5e5;
    width: 100%;
    margin: 24px 0;
  }
  