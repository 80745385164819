.detailForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
  label {
    color: var(--grey-700);
  }
  input {
    border: 1px solid var(--grey-200);
    border-radius: 6px;
    background-color: var(--grey-100);
    color: var(--grey-700) !important;
    padding: 8px 12px;
  }
}
