.signup-screen6-container .note{
    line-height: 24px;
}
.signup-screen6-container .loader-container{
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 768px){
    .signup-screen6-container{
        margin-top: 64px;
    }
    .s6font{
       min-width: 350px !important;
       margin-left: 25px !important;


    }
    .screen6-form{
        margin-top: 40px !important;
        margin-left: 25px !important;
        width: 87vw !important

    }
    .btnscreen6{
        position: fixed !important;
        bottom: 24px !important;
    }
    .s6btn{
        width: 335px;
    }
    .signup-screen6-container .loader-container{
        display: flex;
        position: fixed;
        left:100px;
        top:300px
        
    }
    .snackbar{
   display: flex;
   position: fixed;
   left: 250px;
   font-size:14px;
   margin-left: 5px;
    }
}