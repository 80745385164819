.page-not-found-container {
    /* TODO: check border color */
	border-top: 1px solid #dedede;
	
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: auto;
    padding-bottom: auto;

	& > img{
		margin-bottom: 32px;
		padding-top: 10%;
	}

	& > p:nth-child(2), 
	& > p:nth-child(3){
		margin-bottom: 16px;
	}
}