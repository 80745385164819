


.name-container > .textfield-label{
    flex-basis: 50%;
}
.name-container > .textfield-label > div > input{
    width: 100%;
}
.name-container > .textfield-label:first-child{
   margin-right:8px !important;
}
.name-container > .textfield-label:last-child{
    margin-left:8px !important;
 }
.textfield-label{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.toolTip{
	margin-bottom: 1px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 160px;
	font-size: 14px;
  }
.textfield-label > div > input{
    height:36px;
    border: 1px solid #e1e5e2;
    border-radius: 4px;
    outline:none;
    color: #464d48;
    padding: 8px 12px;
    line-height: 160%;
    flex-basis: 100%;
}


.textfield-label > div> input:focus{
    outline:1px solid #34cb65;
}
.textfield-label > div{
    display: flex;
    align-items: center;
    margin-top: 4px;
}
 .textfield-label > div >div{
  border:1px solid #e1e5e2;
  border-radius: 4px 0 0 4px;
  /* padding: 6px 0px 0 6px; */
  padding-right: 6px;
  display: flex;
  align-items: center;
  line-height: 1.2;
  justify-content: center;
  height: 36px;
  width: 63px;
  display: flex;
} 
.textfield-label > div >div >span{
margin-left: 4px;
}
.textfield-label > div > input.inpt-error{
    border-radius: 0 4px 4px 0;
    flex-basis: calc(100% - 63px);
}
.textfield-label p{
    color: #cc4c45;
    margin-top: 4px;
}
.textfield-label input.input-error{
    border: 1px solid #e0544c;
    border-right: none;
    border-radius: 4px 0 0 4px;
}
.textfield-label input.input-error:focus{
    outline: none;
}
.textfield-label .error-icon{
    border: 1px solid #e0544c;
    border-left: none;
    border-radius: 0 4px 4px 0;
    width: 40px;
    padding: 8px 12px;
    height: 36px;
}
.textfield-label > .phone-number input{
    border-radius: 0 4px 4px 0;
}
.textfield-label > .phone-number input.input-error{
    border-radius: 0;
}
.switch.custom-switch{
    height: 22px;
    width: 40px;
}
.custom-switch .slider::before{
    height: 18px;
    width: 18px;
}
.custom-switch input:checked + .slider::before{
    transform: translateX(17px);
}
.textfield-label > div > div.cc-dropdown{
    position: absolute;
    top: 36px;
    left: 0px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    height: max-content;
    width: 281px;
    padding-right: 0;
}
.cc-container{
    padding-left: 6px;
}
.cc-dropdown .dialer--cc_options{
    width: 100%;
}
.tab-container{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    border-bottom: 1px solid #e1e5e2;
    padding: 0 24px;
}
.tab{
    padding-bottom: 15px;
    cursor: pointer;
    border-bottom: none;
}
.active-tab{
    color: #34cb65;
    border-bottom: 3px solid #34cb65;
    cursor: pointer;
}