@media only screen and (max-width:768px) {
    .rules-container{
        width:312px;
    height: 432px;

    }
    .btn{
        width: 370px
    }
    .modal{
        width: 100vw !important;
    }

}
