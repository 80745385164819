/* .dashboard_filter-row-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  margin-top: -35px;
} */
.dashboard_filter-row-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: -24px;
}
.dashboard_filter-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dashboard_filter-row-clear {
  color: #c6ccc8;
  font-size: 16px;
  line-height: 16px;
  padding-left: 10px;
}
.dashboard_date-filter-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: 10px;
  width: max-content;
  border: 1px solid #e1e6e2;
  border-radius: 10px;
  z-index: 200;
  background-color: #fff;
  box-shadow: 0px 12px 16px -4px #10182814;
}
.dashboard_filter-list-container {
  border-right: 1px solid var(--grey-200);
  padding: 12px 16px;
  z-index: 199px;
}

.dashboard_date-filter-menu .rs-picker-toolbar {
  display: none;
}

/* QUICKFIX: filter overrides */
.dashboard_date-filter-menu .gheader-container > .bottom-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
}

.dashboard_date-filter-menu .gheader-container > .gsep {
  display: none;
}

.dashboard_date-filter-menu .gheader-container {
  display: flex;
  height: 100px;
}

.rs-calendar-header-title {
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: center !important;
  color: var(--grey-700) !important;
  margin-top: 3px !important;
}

.rs-calendar-header-backward .rs-icon {
  width: 20px;
  height: 20px;
  color: #3f3f46;
}
.rs-calendar-header-forward .rs-icon {
  width: 20px;
  height: 20px;
  color: #3f3f46;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #3f3f46;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}
.dashboard_date-menu-btn-container {
  border-radius: 0px 0px 6px 6px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px;
  background: #fff;
  /* position: sticky; */
  bottom: 0px;
  align-self: flex-end;
}
.filter_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grey-200);
  padding: 8px 7px 8px 12px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 16px;
  font-family: "Inter";
  cursor: pointer;
}
.filter_container:hover,
.filter_container:active {
  background-color: var(--grey-50);
}
.filter_container:focus {
  box-shadow: 0px 0px 4px 4px #34cb65;
}
.filter_container img {
  margin-left: 6px;
  margin-top: 2px;
}

.time-filter {
  border-top: 1px solid var(--grey-200);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 16px;
  div:last-child {
    margin-top: 10px;
  }
  div {
    display: flex;
    align-items: center;
    h6 {
      margin-right: 12px;
      color: var(--grey-700);
      width: 36px;
    }
    input {
      border-radius: 6px;
      border: 1px solid var(--grey-200);
      padding: 8px 12px;
      height: 36px;
      color: var(--grey-700);
    }
    input:disabled {
      cursor: not-allowed;
      background-color: var(--grey-100);
    }
    input:first-of-type {
      width: 176px;
    }
    input:last-of-type {
      margin-left: 8px;
      width: 64px;
      text-align: center;
      padding: 8px;
    }
    input::placeholder {
      color: var(--grey-400);
    }
  }
}
.rs-picker-disabled {
  opacity: 0 !important;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-header-row
  .rs-calendar-table-cell-content {
  height: 40px !important;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rs-calendar-table-cell-content {
  padding: 4px !important;
}
.rs-calendar-view {
  padding: 0 20px !important;
}
.rs-picker-daterange-menu .rs-calendar-header {
  width: 280px !important;
}
.rs-btn-icon.rs-btn-xs {
  padding: 6px 0 !important;
}
.rs-picker-daterange-menu .rs-calendar {
  height: 310px !important;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  border-radius: 50% !important;
}

.rs-picker-daterange-header {
  display: none !important;
}
.rs-calendar-table-cell-in-range:before {
  display: none !important;
}
div.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start {
  margin-top: -50px !important;
  margin-left: -6px !important;
  box-shadow: none !important;
}
.rs-calendar-header-title-date.rs-calendar-header-error.rs-btn.rs-btn-subtle.rs-btn-xs {
  color: #3f3f46 !important;
  background-color: white !important;
}
div.rs-calendar-table-cell.rs-calendar-table-cell-in-range {
  background-color: #F1FCF4 !important; 
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-day::after {
  content: "" !important;
  width: 5px !important;
  height: 5px !important;
  background-color: var(--green-600) !important;
  border-radius: 50% !important;
  display: block !important;
  margin: 0 auto !important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: none !important;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell
  .rs-calendar-table-cell-content {
  color: var(--grey-700) !important;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: var(--grey-50) !important;
  color: var(--grey-700) !important;
}
.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content {
  opacity: 0.5; 
}
.rs-calendar-table-cell-disabled {
  opacity: 0.5;
}


.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-disabled
  .rs-calendar-table-cell-content {
  text-decoration: none !important;
  color: var(--grey-300) !important;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-disabled:hover
  .rs-calendar-table-cell-content {
  background-color: transparent !important;
  color: var(--grey-300) !important;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  background-color: var(--green-500) !important;
  color: var(--white) !important;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-in-range:hover
  .rs-calendar-table-cell-content {
  background: var(--green-100) !important;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content {
  background-color: var(--green-500) !important;
  color: var(--white) !important;
}
.rs-calendar-table-cell-in-range {
  background-color: var(--green-50) !important;
}
.rs-calendar-table-cell-selected {
  background-color: #f1fcf4 !important;
}
