.viewMoreBtn:hover,.viewMoreBtn:hover >div{
    color: #28994B !important;
}
.searchVnForm{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin: 32px 0 8px 0;
}
.searchVnForm > div:first-child{
    width: 160px;
}
.searchVnForm > label {
    width: 240px;
}
.modalBody{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e1e5e2;
    border-radius: 6px;
    padding: 12px 16px;
}
.modalBody p,.modalBody span{
    line-height: 180%;
}
.infoHeader{
    border-radius: 6px;
    border: 1px solid  #34cb65;
    display: flex;
    padding: 12px 16px;
    margin-top: 40px;
    }
.maximizeBenefitBox{
    border-radius: 6px;
    border: 1px solid  #34CB65;
    background: #FFF;
    padding: 24px;
    width: 100%;
    margin-bottom: 24px;
}
.maximizeBenefitBox > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.maximizeBenefitBox > p:nth-child(2) {
    margin: 16px 0;
    line-height: 180%;
}
.maximizeBenefitBox > div span{
    cursor: pointer;
}
.maximizeBenefitBox .chatSupport{
    color: #2eb258;
    text-decoration: underline;
    cursor: pointer;
}