.gmodal-background {
  background-color: rgba(102, 102, 102, 0.299);
  display: flex;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(3px); */
  transition: all 0.4s;
}

.gmodal-body-inner{
  width: fit-content;
  height: fit-content;
  /* max-height: calc(456px - 136px); */
  border-radius: 6px;
  background-color: white;
  padding-right: 5px;
  position: relative;
  z-index:2;
  overflow-y: scroll;
}

.gmodal-foreground-wrapper {
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #e1e5e2;
}

.gmodal-foreground {
  background-color: white;
  width: auto;
  min-width: 440px;
  border-radius: 10px;
  outline: none;
}

.gmodal-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background: linear-gradient(0deg, #f7faf8, #f7faf8),
    linear-gradient(0deg, #e1e6e2, #e1e6e2); */
  /* Neutral Colors/Grey - 100 */
  background: #F7FAF8;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid #e1e5e2;
  font-size: 1em;
  position: relative;
  padding-left: 24px;
  height: 56px;
  position: relative;
  z-index: 5;
}

.gmodal-close-btn {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right:  20px;
  margin-top: calc(1px + 0px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gmodal-foreground {
  min-height: 15vh;
  padding: 32px 24px 32px 24px;
}

.gmodal-buttons-container{
  padding: 20px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 0.75px solid #E1E6E2;
  border-radius: 0px 0px 10px 10px;
}

.gmodal-buttons-container > * {
  margin-right: 12px !important;
}
@media only screen and (max-width: 768px){
  .gmodal-foreground {
    min-width: 0px;
  }
}
