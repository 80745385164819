.surveyViewContainer{
    padding: 24px 32px;
}
.noOverflow{
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 196px;
}
.breadcrumbLink:hover{
    color: #464d48;
}