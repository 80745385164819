.tabContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
 }
 .tabContainer > hr{
    background-color: #e1e5e2;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 0;
 }
 .tabContainer > hr:first-child{
    width: 32px;
 }
 .tabLabel{
    padding: 12px 24px;
    background-color: #f7faf8;
    border: 1px solid #e1e5e2;
    cursor: pointer;
    margin-top: 8px;
 }
 .selectedTabLabel{
    padding: 16px 24px 12px;
    background-color: #fff;
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    font-weight: 500;
    margin-top: 0;
 }