
@media only screen and (max-width: 768px){
    .s7btn{
        width: 335px !important;
    }
    .fonts7{
        font-size: 12px !important;
    }
    .s7{
        margin-top: 30px !important;
    }
    .signup-screen-form > .title {

        margin-bottom: 30px !important;

    }
    .res{
       margin-left: 12% !important;
    }

}