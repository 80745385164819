.call-logs-override-screen-container{
	/*option-1*/
	/* max-height: calc(100vh - var(--top-nav-height) - 8px) !important; */
	height: fit-content;
	overflow: hidden;
	width:100%;
	left:0;
}

.call-logs-gtable{
	/*option-1*/
	/* border: 2px solid red !important;
	flex: 1 !important;
	height: calc(100vh - 300px - var(--top-nav-height) - 8px) !important;
	overflow-y: scroll !important; */

	/*option-1.2*/
	/* height: calc(100vh - 300px - var(--top-nav-height) - 8px) !important; */
	height: calc(100vh - 102px - var(--top-nav-height) - 8px) !important;
	overflow-y: hidden !important;
	overflow-x: hidden !important;
}

.call-logs-gtable .MuiDataGrid-virtualScroller {
	/* height: calc(100vh - 409px - var(--top-nav-height) - 8px) !important; */
	height: calc(100vh - 210px - var(--top-nav-height) - 8px) !important;
	overflow-y: scroll !important;
}

.call-logs-gtable .MuiDataGrid-footerContainer {
	height: 30px;
	border-radius: 4px;
	width: calc(100vw - var(--side-nav-width) - 64px);
	position: absolute;
	left: calc(100vw - var(--side-nav-width) - 64px)/2;
	bottom: 0;
	min-width: 450px !important;
}

.call-logs-gtable .logs-table-header-call-reason-outcome {
	padding-left: 0px;
}

.call-logs-override-screen-container .left-header input[type="search" i]::-webkit-search-cancel-button, 
.call-logs-override-screen-container .gtable-container
  :is(.right-header, .left-header)
  .search
  > input[type="search" i]::-webkit-search-cancel-button {
	display: none !important;

}
.contactIcons{
    padding: 10px 8px 6px 8px;
	display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
}
.contactIcons:hover{
	background-color: #f5f7f6;
	border-radius: 4px;
}
.contactIcons:hover svg path{
    fill: #23b258 ;
    stroke: #23b258 ;
    cursor: pointer;
}
.message-disabled:hover svg path{
    stroke: #c6ccc8;
    fill: none;
    cursor:default;
}
.call-logs-gtable .table-cell-edit{
	margin-left: -20px;
	width: 80px;
}
