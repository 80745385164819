.signup-screen4-container .form{
	min-height: 275px;
	margin-left:8.5%;
    margin-right:9.25%;
    display: flex;
    flex-direction: column;
}

.signup-screen4-container .title{
	margin-top:16%;
    margin-bottom:8%;
}

/* NOTE: sales support etc. checkboxes */
.uses-field > input:checked + label {
  color: #34cb65 !important;
}

/* .signup-screen3-form > form > label  */
@media only screen and (max-width: 768px) {


/* .buttons-container {
    position: fixed;
    bottom: 24px;

} */
 .buttons-container{
  justify-content: center !important;
  margin-bottom: 24px;
}
.screen4{
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
}
.signup-screen-form4{
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.t4 {
  margin-left:32px !important;
}
}