.s1-embed-container-styles{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    border: 1px solid rgba(63,95,172,0.35);
    box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);
    border-radius:10px;
}
.s1-preview-heading-styles{
    position:absolute;
    inset:0;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background-color:rgba(40, 37, 54, 0.9);
    z-index:999999;
    font-family:Poppins, Arial, sans-serif;
    font-size:clamp(20px, 2.664vw, 28px);
    font-weight:500;
    line-height:normal;
    text-align:center;
    border-radius:10px;
}
.s1-embed-styles{
    position:relative;
    padding-bottom:calc(52.38% + 27px);
    width:100%;
    height:0;
    transform:scale(1);
    overflow:hidden;
}
.s1-preview-styles{
    width:100%;
    height:100%;
    z-index:99999;
    position:absolute;
    background:url('https://storylane-prod-uploads.s3.us-east-2.amazonaws.com/company/company_c83a1d5a-ffec-4e3f-8577-ea49a888b863/project/project_63bc66c8-7c4a-45c6-a70e-10c949943a25/page/1709794214193') no-repeat;
    background-size:100% 100%;
    border-radius:inherit;
}
.s1-preview-cta-styles{
    background-color:#34cb65;
    border:none;
    border-radius:8px;
    box-shadow:0px 0px 15px rgba(26, 19, 72, 0.45);
    color:#FFFFFF;
    display:inline-block;
    font-family:Poppins, Arial, sans-serif;
    font-size:clamp(16px, 1.599vw, 20px);
    font-weight:600;
    height:clamp(40px, 3.996vw, 50px);
    line-height:1.2;
    padding:0 clamp(15px, 1.776vw, 20px);
    text-overflow:ellipsis;
    transform:translateZ(0);
    transition:"background 0.4s";
    white-space:nowrap;
    width:auto;
    z-index:999999;
    cursor:pointer;
}
.s1-preview-cta-ripple-styles{
    position:absolute;
    border:1px solid #34cb65;
    inset:0;
    border-radius:inherit;
    pointer-events:none
}
.s1-preview-cta-ripple-shadow-styles{
    box-shadow:#34cb65 0px 0px 4px 4px;
    opacity:0;
    border-radius:inherit;
    position:absolute;
    inset:0
}
.s1-demo-styles{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border:none;
}
.sl-preview-cta:hover .sl-preview-cta-ripple{
    transition:all 1s cubic-bezier(0,0,.2,1);
    inset:-0.75em!important;
    opacity:0!important
}
.sl-preview-cta:hover .sl-preview-cta-ripple-shadow{
    opacity:0.125!important;
}
@media (max-width: 1024px) {
    .sl-heading-text {
      max-width:90%;
    }
  }