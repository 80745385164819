.box-container {
   padding: 24px;
   background-color: #F7F9FB;
   border-radius: 8px;
 }
 
 .access-container {
   width: 200px;
   margin-bottom: 16px; 
 }
 
 .autocomplete-container {
   width: 100%;
   margin-bottom: 16px;
 }
 
 .selected-item {
   display: flex;
   align-items: center;
   margin-top: 8px;
 }
 
 .selected-users-teams {
   margin-top: 16px;
 }
 
 .avatar-margin {
   margin-right: 8px;
 }
 
 .tabContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
 }
 .tabContainer > hr{
    background-color: #e1e5e2;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 0;
 }
 .tabContainer > hr:first-child{
    width: 32px;
 }
 .tabLabel{
    padding: 12px 24px;
    background-color: #f7faf8;
    border: 1px solid #e1e5e2;
    cursor: pointer;
    margin-top: 8px;
 }
 .selectedTabLabel{
    padding: 16px 24px 12px;
    background-color: #fff;
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    font-weight: 500;
    margin-top: 0;
 }
 ul.deleteModal{
  padding-top: 20px;
  padding-left: 15px;
 }

 .deleteModal li{
  line-height: 25.2px;
 }