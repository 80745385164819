.reset-password-btn{
    margin-top:36px;
}

.to-login{
    text-align:center;
    margin-top:32px;
    /* padding-right:12px; */
    margin-left:auto;
    margin-right:auto;
    width:fit-content;
}

input.forgot-password{
    border: 1px solid #e1e5e2;
}