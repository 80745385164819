.row-view-btn {
  background-color: transparent;
  color: #28994b;
  text-decoration: underline;
  outline: none;
}

.voice-broadcast-datetime {
  display: grid;
  grid-template-columns: 48% 48%;
  /* margin-top: 24px; */
  column-gap: 16px;
  row-gap: 24px;
}

#datetime-dropdown {
  background-color: white;
  border: 1.5px solid #e1e6e2;
  border-radius: 2px;
  height: 40px;
  padding: 0 4%;
}

.invalid-contacts-notification {
  border: 1px solid #cc4c45;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
}

.invalid-contacts-notification button {
  background-color: transparent;
  color: #2eb358;
  text-decoration: underline;
}

.invalid-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 22px;
  padding-bottom: 22px;

  border-bottom: 1px solid #f5f7f6;
  border-radius: 6px;
  width: 100%;
  height: 56px;
}

.invalid-contact > input {
  border: 1px solid #e1e5e2;
  padding: 8px 12px;
  border-radius: 4px;
  height: 36px;
}

.invalid-contact button {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 1px solid #e1e6e2;
  border-radius: 4px;
}

.invalid-contact-check-btn {
  background: url("../../assets/error-contact-check.svg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 36px;
  height: 36px;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.invalid-contact-check-btn:disabled {
  background: url("../../assets/error-contact-close-disabled.svg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 36px;
}
/* .contactIcons{
  padding: 8px;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
}
.contactIcons:hover{
background-color: #f5f7f6;
  border-radius: 4px;
}

.contactIcons:hover svg path{
  fill: #23b258 ;
  stroke: #23b258 ;
  cursor: pointer;
}
.contactIcons.messageDisabled:hover svg path{
  stroke: #c6ccc8;
  fill: none;
  cursor:default;
} */

.assignToDropdown{
  position:absolute;
  top:calc(50vh + 34px);
  background-color:#fff;
  z-index:1500;
  opacity:1;
  box-shadow: 0px 4px 8px 8px rgba(95, 102, 97, 0.04);
  border-radius: 6px;
  width: 320px;
}
.assignToDropdown .pNoOverflow{
  max-width: 280px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}