.call-pricing-container .MuiTablePagination-selectLabel,
.call-pricing-container .MuiTablePagination-selectLabel > .MuiInputBase-root,
.call-pricing-container .MuiTablePagination-select,
.call-pricing-container .MuiTablePagination-selectIcon {
	display: none !important;
}

.call-pricing-container .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
	padding-left: 12px !important;
}

.call-pricing-container .MuiDataGrid-cell--textLeft[data-field="to"]{
	padding-left: 8px !important;
}