.signup-screen-container{
    padding:0;
    margin:0;
    /* border:1px solid black; */
    display: flex;
    flex-direction: row;
}
.signup-screen-art{
     /* border:1px solid red; */
     height:100vh;
     background:#F3FCF7;
     /* centering the vector art with human in it */
     display:flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     position: relative;
     max-width:50vw;
     min-width:50vw;
}
.signup-screen-form{
      /* border:1px solid red; */
      width:50vw;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      /* align-items: center; */
}
.signup-screen-form form{
    /* border:1px solid black; */
    /* width: */
    margin-left:17.5%;
    margin-right:17.5%;
    display: flex;
    flex-direction: column;
    min-width: 338px;
}
.signup-screen-form > .title{
    margin-left:17.5%;
    margin-right:auto;
    /* margin-top:12%; */
    margin-bottom:56px;
    text-align:left ;
    /* border:1px solid red; */
    width:65%;
    min-width:250px;
}
.signup-screen-form > form > .sub-title{
    margin-bottom:24px;
    line-height: 28px;
}
.select-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 24px;
  }
.select-container label{
    margin-bottom: 4px;
  }
/* input{
    height: 36px;
    padding: 8px 12px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #e1e5e2;
  } */
.signup-screen-container input:focus{
  border-color: #34cb65;
  }
.signup-screen-form form .buttons-container { 
    /* border:1px solid red; */
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    margin-top: 14%;
}  