.billingInfoForm{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 362px;
    
}
.billingInfoForm label{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.billingInfoForm label > span {
    margin-bottom: 4px;
}
.billingInfoForm label input{
    margin-bottom: 4px;
    width: 362px;
}
.billingInfoForm label > div > input{
    width: calc(50% - 2px);
}
.billingInfoForm label > div > input:first-child{
    margin-right: 4px;
}
.billingInfoForm label input::placeholder{
color: #C6CCC8;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 160%; 
}
.billingInfoForm label > input:focus{
 outline: 1px solid #34cb65;
}
.flexContainer{
    display: flex;
    align-items: center;
}
.input{
    height: 36px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #e1e5e2;
  }