input::-webkit-inner-spin-button{
	display:none;
}

.MuiDataGrid-columnHeader.autodial-view-screen-table-header-contacted[data-field="userRole"],
.MuiDataGrid-columnHeader.autodial-view-screen-table-header-contacted[data-field="userVirtualNumber"],
.MuiDataGrid-columnHeader.autodial-view-screen-table-header-contacted[data-field="userTeam"],
.MuiDataGrid-columnHeader.autodial-view-screen-table-header-contacted[data-field="userStatus"],
.MuiDataGrid-columnHeader.autodial-view-screen-table-header-contacted[data-field="userLicense"]
 { 
	padding-left: 24px !important;
}
.warning-container{
    background-color: #fefbe8;
    display: flex;
    border-radius: 6px;
    padding: 12px 16px;
    color: #464c48;
}
.warning-container span{
    margin-top: 8px;
}
.warning-container p{
    margin-left: 12px;
}
.delete-modal-ul{
    padding: 32px 32px 8px;
    width: 545px;
    margin-bottom: 0;
}
.user-license-info{
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid  #34cb65;
    color: #464c48;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.user-license-info > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.user-license-info > div > p{
    margin: 0;
}
.user-license-info .view-more-btn{
    color: #27ae60; 
    background: none; 
    border: none; 
    cursor: pointer; 
    padding: 0;
    display: flex;
    align-items: center;
}
.empty-state-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - var(--top-nav-height) - 50px);
    margin-top: -40px;
}
.empty-state-container p{
    margin: 16px 0 24px 0;
}
.spanNoOverFlow {
    display: "inline-block";
    overflow: "hidden";
    text-overflow: "ellipsis";
    white-space: "nowrap";
    width: "100%";
  }
.filter-btn-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}