.container{
    padding: 40px 32px;
}
.container section:nth-child(1){
    border-bottom: 1px solid #e1e5e2;
}
.container section:nth-child(1) form{
    margin: 40px 0;
}
.container section:nth-child(2){
    border-bottom: 1px solid #e1e5e2;
}
.container section:nth-child(2) h2, .container section:last-child h2{
    margin: 40px 0;
}

.contactContainer{
    display: flex;
    width: 406px;
    gap: 12px;
    align-items: flex-start;
}
.contactContainer label{
    width: 100%;
}
.contactContainer span{
    margin-top: 4px;
    cursor: pointer;
}
.addContactBtn{
    background-color: #fff;
    color: #2eb258;
    display: flex;
    align-items: center;
}
.addContactBtn:hover{
    color: #28994b;
}
.addContactBtn:disabled{
    color: #C6CCC8;
}
.container section:nth-child(2) p{
    margin-bottom: 16px;
}
.header{
    padding-left:24px !important;
    font-size: 14px !important;
}
.downloadItemType{
    background-color: #fff;
    color: #2eb258;
    line-height: 160%;
    text-decoration: underline;
    text-transform: capitalize !important;
}
.downloadItemType:hover, .downloadItemType:visited, .downloadItemType:focus, .downloadItemType:active{
    color: #2eb258 !important;
}