.clear-gfilters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.clear-gfilters:hover {
  text-decoration: underline;
}
.gfilter-container {
  border: 1px solid #e1e5e2;
  margin-right: 12px;
  border-radius: 4px;
  padding: 12px 10px 12px 12px;
  cursor: pointer;
  opacity: 1;
  position: relative;
  z-index: 1;
  background-color: #fff;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}
.gfilter-container > span {
  display: inline-block;
  margin-left: 10px;
  margin-top: -2px;
}
.gfilter-container .title-text{
  margin-right: 4px;
  line-height: 24px;
}
.gfilter-container .badge-container {
  margin: -2px 2px 0 3px;
}
.gfilter-container .applied-filters-length {
  width: 30px;
  height: 20px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #34cb65;
  color: #fff;
}
.search-container{
  background-color: #f7faf8;
  padding: 16px 20px;
  border-bottom: 1px solid;
  border-bottom-color: #e1e5e2;
  margin-top: -8px;
  position: sticky;
  z-index: 3;
  top: 0px;
}
.search-input-container{
  border: 1px solid #e1e5e2;
  max-height: 36px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px; 
  display: flex;
  align-items: center;
  background-color: #fff;
}
.search-input-container input { 
  border: none;
  border-right:none;
  border-left:none; 
  padding-left:4px; 
  width: 100%;
}
.gfilter-container .span-no-overflow {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.options-wrapper{
  max-height: 150px;
  width: 310px;
  overflow: auto;
}