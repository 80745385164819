.container {
  border: 1px solid var(--grey-200);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 75.5px 0;
  text-align: center;

  div {
    border: 1.5px solid var(--grey-100);
    height: 64px;
    width: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    color: var(--grey-700);
    margin-top: 20px;
    margin-bottom: 8px;
  }

  h6 {
    color: var(--grey-500);
    margin-bottom: 32px;
  }

  button {
    background-color: var(--green-500);
    border-radius: 6px;
    padding: 10px 16px;
    color: var(--white);
  }
}
