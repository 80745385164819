/* ------------------ Copied from CampaignStyles.css ------------------ */

/* NOTE: overlay for campign page when popup is open*/
.add-new-campaign-overlay-temp {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  /* border:1px solid black; */
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
}

/* ------------------ END OF FILE CampaignStyles.css ------------------ */

/* ------------------ Copied from campaignViewStyles.css ------------------ */

:root {
  --title-temp-translate: 34px;
  --title-temp-width: 150px;
}

.add-new-campaign-overlay-temp {
  height: 300vh !important;
  z-index: 8;
}

.error-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  justify-content: space-evenly;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

.error-contact:last-child {
  border: none;
}

.error-contact > input {
  border: 1px solid #dddddd;
  padding: 9px 12px 9px 12px;
  border-radius: 3px;
}

/* ------------------ END OF FILE campaignViewStyles.css ------------------ */

/* ------------------ Copied from callLogsStyles.css ------------------ */

.call-logs-container {
  position: absolute;
  top: calc(var(--top-nav-height));
  left: var(--side-nav-width);
  height: calc(100vh - var(--top-nav-height));
  width: calc(100vw - var(--side-nav-width));
  /* margin-top: -8px; */
}

.call-logs-container > .title {
  height: 106px;
  padding-top: 32px;
  padding-left: 40px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}

.call-logs-controls {
  margin-left: 40px;
  margin-top: 40px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.call-logs-controls > .filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  width: fit-content;
  margin-top: 28px;
  width: calc(100vw - 80px - var(--side-nav-width));
}

/* rsuite-container STYLES */
.daterange-picker-container {
  position: absolute;
  right: 40px;
  user-select: none !important;
}

/* rsuite-container STYLES end */
.call-logs-container input,
.call-logs-container button {
  border: none;
  outline: none;
  cursor: pointer;
}

.call-logs-container input[type="text"] {
  cursor: text;
}

.call-logs-controls > .search {
  background: url("../../assets/SearchIcon.svg") no-repeat 8px 9px;
  width: 292px;
  height: 37px;
  padding-left: 32px;
  border: 1px solid #dedede;
  border-radius: 2px;
  color: #464d48;
  cursor: text;
}

.call-logs-controls > .search::placeholder {
  color: #dedede;
}

.call-logs-controls > .filters > .add-filter,
.call-logs-controls > .filters > .refresh,
.call-logs-controls > .filters > .download {
  position: relative;
  width: 36px;
  height: 32px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-right: 16px;
  font-size: 16px;

  font-style: normal;
  color: #464d48;
}

.call-logs-controls > .filters > .add-filter {
  background: url("../../assets/call-logs-add-filter.svg") no-repeat 24px 10px;
  padding-left: 26px;
  width: 134px;
  height: 32px;
}

.call-logs-controls > .filters > .add-filter:after,
.call-logs-controls > .filters > .refresh:after,
.call-logs-controls > .filters > .download:after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f7f7f7;
  z-index: -1;
  position: absolute;
}

.call-logs-controls > .filters > .refresh {
  background: url("../../assets/call-logs-refresh.svg") no-repeat 12px 9.5px;
}

.call-logs-controls > .filters > .download {
  background: url("../../assets/call-logs-download.svg") no-repeat 11px 9px;
  margin-right: 0;
}

/* z-index on all buttons and dropdowns of filters */
.filters {
  z-index: 7;
}

/* ------------------ END OF FILE callLogsStyles.css ------------------ */

/* ------------------ Copied from callLogsViewStyles.css ------------------ */

.recording-container {
  position: absolute;
  top: calc(var(--top-nav-height));
  left: var(--side-nav-width);
  /* height: calc(100vh - var(--top-nav-height)); */
  width: calc(100vw - (var(--side-nav-width) + 16px));
  border: 1px solid #dddddd;
  /* margin-left:32px; */
  padding-left: 32px;
  /* padding-bottom:100px; */
  height: fit-content;
  border-left: none;
}

.recording-container input[type="text"]:read-only {
  color: #464d48;
  border-color: #dedede;
}

.recording-sep {
  margin-top: 37px;
  height: 1px;
  background: #dedede99;
  width: calc(100vw - calc(1.37 * var(--side-nav-width)));
}

.recording-sep-title {
  margin-left: -33px;
  width: calc(100vw - calc(1 * var(--side-nav-width)));
}

.recording-details-container {
  border: 1px solid #dddddd;
  margin-right: 32px;
  margin-top: 40px;
  border-radius: 5px;
  position: relative;
}

.recording-user-details {
  margin-top: 40px;
  margin-left: 32px;
  margin-right: 32px;
}

/* recording-buttons */
.recording-user-details > div:first-child {
  /* TODO: call and share buttons disabled till data is fetched */
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -17px;
}

.recording-user-details > div:nth-child(2) > img {
  cursor: pointer;
}

/* recording buttons call and share */ /*@remove*/
/* .recording-buttons {
  float: right;
  margin-right: 64px;
}

.recording-buttons > button:nth-child(1) {
  background: url("../../assets/recording-phone.svg") no-repeat 10px 10.5px;
  position: relative;
  margin-right: 16px;
  overflow: hidden;
}

.recording-buttons > button:nth-child(2) {
  background: url("../../assets/recording-share.svg") no-repeat 10px 10px;
  position: relative;
  margin-right: 16px;
  overflow: hidden;
}

.recording-buttons > button:nth-child(1):before,
.recording-buttons > button:nth-child(2):before {
  position: absolute;
  content: "";
  background: #34cb65;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

/* name */
.recording-user-details > div:nth-child(2) > *:first-child {
  margin-right: 16px;
  border: none;
}

.recording-user-details > .call-time {
  display: flex;
  margin-top: 32px;
}

/* calendar image in call-time */
.recording-user-details > .call-time > img:first-child {
  margin-right: 4px;
}

.recording-user-details > .call-time > div:nth-of-type(1),
.recording-user-details > .call-time > div:nth-of-type(2),
.recording-user-details > .call-time > div:nth-of-type(3) {
  margin-right: 2px;
}

.recording-user-details > .call-time > div:nth-of-type(3) {
  margin-right: 4px;
  padding-right: 10px;
  border-right: 1.5px solid #4a4a4ad1;
}

.recording-user-details > .call-time > img:nth-last-of-type(1) {
  margin-left: 32px;
  margin-right: 4px;
}

/* Call details */
.call-details-and-recording {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  justify-content: space-between;
  /* flex-grow: none; */
}

.recording-call-details {
  margin-left: 32px;
  /* width:394px; */
}

.recording-call-details > p:nth-child(1) {
  margin-bottom: 32px;
}

.recording-call-details > div {
  margin-bottom: 24px;
}

.recording-call-details > div:last-child {
  margin-bottom: 123px;
}

.recording-call-details > div:nth-of-type(n) > span:first-child {
  /* border:1px solid black; */
  margin-right: 4px;
}

.recording-call-details select {
  padding: 8px 12px 8px 12px;
  border: 1px solid #dedede;
  border-radius: 3px;
  color: #464d48;
}

.recording-call-details select::-webkit-input-placeholder {
  color: #dedede;
}

/* call recording section (displays audio)*/
.recording-call-recording {
  margin-left: 32px;
  margin-right: 32px;
  width: 675px;
}

.recording-call-recording > p:first-child {
  margin-bottom: 24px;
}

.recording-call-recording > .audio {
  height: 87px;
  width: 100%;
  margin-bottom: 24px;
}

.recording-call-recording .call-notes {
  display: inline-block;
  margin-bottom: 24px;
}

.recording-call-recording textarea {
  width: 100%;
  height: 130px;
  padding: 12px;
  margin-bottom: 24px;
  border: 1px solid #dedede;
  border-radius: 1.91197px;
}

.screen-container input[type="text"]:read-only,
.screen-container input[type="tel"]:read-only {
  color: #2e3330;
}

/* ------------------ END OF FILE callLogsViewStyles.css ------------------ */

/* ------------------ Copied from deniedStyles.css ------------------ */

.access-denied-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.access-denied-container > p {
  margin-top: 32px;
}

.access-denied-container > p:nth-last-of-type(1) {
  margin-top: 16px;
  margin-bottom: 32px;
}

.access-denied-container > button {
  width: 107px;
}

/* ------------------ END OF FILE deniedStyles.css ------------------ */


/* ------------------ Copied from VoiceBroadcast.css ------------------ */

.row-options-backdrop {
	background-color: transparent;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
  }
  
  .row-options-foreground {
	position: fixed;
	top: 0;
	left: 0;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	z-index: 101;
	border-radius: 4px;
  }
  
  .row-options-foreground button {
	display: block;
	background-color: white;
	width: 100%;
	padding: 6px 16px;
	margin: 2px 0;
  }
  
  .row-options-foreground button:hover {
	background-color: #f5f7f6;
  }
  
  
  .campaign-status {
	padding: 6px 10px;
	border-radius: 4px;
  }

/* ------------------ END OF FILE VoiceBroadcast.css ------------------ */

/* injected styles for pages without stylesheet */
/* autodialViewScreen */
.autodial-view-screen-container .logs-table-header-call-reason-outcome {
	padding-left: 24px !important;
}

/* autodial screen */

.MuiDataGrid-columnHeader.autodial-screen-table-header-contacts {
	padding-left: 10px !important;
}

.MuiDataGrid-columnHeader.autodial-view-screen-table-header-contacted { 
	padding-left: 10px !important;
}



/* vn screen */
.MuiDataGrid-columnHeader[data-field="number"],
.MuiDataGrid-columnHeader[data-field="location"],
.MuiDataGrid-columnHeader[data-field="type"],
.MuiDataGrid-columnHeader[data-field="users"] {
	padding-left: 24px !important;
}
/* vn view screen outgoing calls tab */
.MuiDataGrid-columnHeader[data-field="role"],
.MuiDataGrid-columnHeader[data-field="team"] {
	padding-left: 24px !important;
}


/* shared call recording styles */
.shared-call-recording-insights-container.insights-container {
	border: 1px solid #e1e6e2;
	border-radius: 6px;
}

.shared-call-recording-insights-container.insights-container .recording {
	/* height: 199px; */
	padding: 32px;
	border-bottom: 1px solid #e1e6e2;
}

.shared-call-recording-insights-container.insights-container .section_1 {
	min-width: 385px;
	border-right: 1px solid #e1e6e2;
	padding: 32px;
}

.shared-call-recording-insights-container.insights-container .section_2 {
	padding: 32px;
	width: 100%;
}

.shared-call-recording-insights-container.three-dots-btn {
	width: 40px;
	height: 40px;
	border: 1px solid #e1e6e2;
}
