/* TODO: Need to set body padding to 0 from 100+ which is being set from\&for call-logs */
.signup-container{
    padding:0;
    margin:0;
    /* border:1px solid black; */
    display: flex;
    flex-direction: row;
}

.signup-art{
    /* border:1px solid red; */
    height:100vh;
    background:#F3FCF7;
    /* centering the vector art with human in it */
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width:50vw;
    min-width:50vw;
}

.signup-art > a{
    transform:scale(0.85);
}

/* LOGO */
.signup-art > a{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 40px;
    margin-left: 40px;
}

/* TOP DOTS */
.signup-art > img:nth-child(2){
/* border:1px solid blue; */
    position: absolute;
    right:0;
    top:0;
}

/* MAIN VECTOR IMAGE WITH HUMAN */
.signup-art > img:nth-child(3){
    /* border:1px solid blue; */
    /* margin-left:px; */
    margin:auto;
}

/* BOTTOM DOTS */
.signup-art > img:nth-child(4){
    /* border:1px solid blue; */
    position: absolute;
    bottom:0;
    left:0;
}

/*  */
.signup-art > p:last-child{
    /* border:1px solid blue; */
    position: absolute;
    top:65%;
    margin-top:40px;
    font-size: 18px;
    line-height: 160%;
}
.reviews-container{
    background-color: #fff;
    border: 3px solid #E5F8EB;
    border-radius: 20px;
    padding: 44px 48px 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    z-index: 10;
    margin: 100px 20px 40px 20px;
    max-width: calc(100% - 40px);
}
.reviews-container ul{
    list-style-type: none;
    margin-bottom: 0;
}
.reviews-container ul li{
    text-align: center;
    margin: 20px auto;
}
.reviews-container ul li p{
    max-width: 373px;
}
.rating-container{
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 12px;
}
.badge-container{
    display: flex;
    gap: 24px;
    margin: 0 20px;
}
.link-text{
    color: #2eb258;
    font-weight: 400;
    font-size: 12px;
    text-decoration: underline;
}
/* NOTE: signup form */
.signup-form{
    /* border:1px solid red; */
    width:50vw;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.signup-form > .title{
    margin-left:auto;
    margin-right:auto;
    /* margin-top:12%; */
    margin-bottom:12%;
}

.signup-form > form{
    /* border:1px solid black; */
    /* width: */
    margin-left:17.5%;
    margin-right:17.5%;
    display: flex;
    flex-direction: column;
}

.signup-form > form > .name-container{
    /* border:1px solid red; */
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

/* .signup-form > form > .name-container > label > div > input{
    flex-basis:50%;
}
.signup-form > form > .name-container > label > div > input.input-error{
    width: 100%;
}
.signup-form > form > .name-container > label:first-child{
   margin-right:8px !important;
}
.signup-form > form > .name-container > label:last-child{
    margin-left:8px !important;
 }
.signup-form > form > .name-container > label{
    flex-basis: 50%;
}

.signup-form > form > label,
.signup-form > form > .name-container > label{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
} */

/* .signup-form > form > label > div > input,
.signup-form > form > .name-container > label > div > input{
    height:36px;
    border: 1px solid #e1e5e2;
    border-radius: 4px;
    outline:none;
    color: #2D2D2D;
    padding:12px 6px 12px 6px;
    flex-basis: 100%;
}

.signup-form > form > label > div>input:focus,
.signup-form > form > .name-container > label > div> input:focus{
    outline:1px solid #34c565;
} */

.signup-btn{
    height:41px;
}

.to-sign-up-container{
    /* border:1px solid black; */
    margin-left:17.5%;
    margin-right:17.5%;
    text-align:center;
    margin-top:32px;
}

.to-terms-and-conditions-container{
    /* border:1px solid black; */
    margin-left:17.5%;
    margin-right:17.5%;
    margin-top:16px;
    margin-bottom:32px;
    line-height: 24px;
}

.to-terms-and-conditions{
    color:#2eb258;
    margin-left:8px;
    cursor: pointer;
    text-decoration: none;
}

.to-login-container{
    /* border:1px solid black; */
    margin-left:17.5%;
    margin-right:17.5%;
    text-align:center;
}

.to-login{
    /* border:1px solid black; */
    /* text-align: right; */
    color:#2eb258;
    margin-bottom:56px;
    cursor:pointer;
    text-decoration: none;
    margin-left:8px;
}
/* .signup-form >form >label > div, .signup-form >form> .name-container > label >div{
    display: flex;
    align-items: center;
}
.signup-form >form >label > div >div{
  border:1px solid #e1e5e2;
  border-radius: 4px 0 0 4px;
  padding: 6px 0px 0 6px;
  height: 36px;
  width: 63px;
  display: flex;
  
}
.signup-form >form >label > div >div >span{
margin-left: 4px;
}
.signup-form >form >label > div > input.inpt-error{
    border-radius: 0 4px 4px 0;
    flex-basis: calc(100% - 63px);

}
.signup-form > form > label p, .signup-form > form >.name-container>label p{
    color: #cc4c45;
    margin-top: 4px;
}
.signup-form > form > label input.input-error, .signup-form > form > .name-container > label input.input-error{
    border: 1px solid #e0544c;
    border-right: none;
    border-radius: 4px 0 0 4px;
}
.signup-form > form > label .error-icon, .signup-form > form > .name-container> label .error-icon{
    border: 1px solid #e0544c;
    border-left: none;
    border-radius: 0 4px 4px 0;
    width: 40px;
    padding: 8px 12px;
    height: 36px;
}*/
/* .signup-form > form > label > .phone-number input{
    border-radius: 0 4px 4px 0;
}
.signup-form > form > label > .phone-number input.input-error{
    border-radius: 0;
}  */
.reviewsContainer {
    justify-content: center;
    border-radius: 10px;
    box-shadow: 20px 8px 80px 0px rgba(0, 0, 0, 0.03);
    border-color: rgba(153, 229, 178, 1);
    border-style: solid;
    border-width: 1px;
    background-color: var(--Interface-Colors-White, #fff);
    display: flex;
    max-width: 405px;
    flex-direction: column;
    color: var(--Neutral-Colors-Grey---900, #464d48);
    padding: 40px;
  }
  .reviewsText {
    font: 500 14px/28px Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .reviewsImg {
    display: flex;
    margin-top: 48px;
    gap: 12px;
  }
  .img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 56px;
    border-radius: 50%;
  }
  .reviewsBottom {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    width: fit-content;
    margin: auto 0;
  }
  .reviewsAuther {
    font: 500 16px Inter, sans-serif;
  }
  .reviewsProd {
    margin-top: 6px;
    font: 400 14px Inter, sans-serif;
  }
  .logo-container{
display: none;
  }
  .error-message {
display: none;
}


@media screen and (max-width: 1024px) {
    .reviews-container{
        padding: 24px 28px 24px;
    }
    .badge-container{
        gap: 15px;
    }
}
@media screen and (max-width: 768px) {
    .to-login-container{
        margin-bottom: 10px;
    }
    .signup-container {
        box-sizing: border-box; 
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
      }
 

      .signup-form > form > .name-container {
        flex-direction: column; 
        
    }
    .signup-form {
      width: 100%;
      justify-content:flex-start;

      
    }
    .signup-art,
    .reviewsContainer {
      display: none;
    }
      .name-container > .textfield-label:last-child {
        margin-left: 0px !important;
    }
    .name-container > .textfield-label:first-child {
        margin-right: 0px !important;
    }
    .logo-image {
        cursor: pointer;
        width: 129.63px;
        height: 35px;
        gap: 0px;
      }
      .logo-container{
        cursor: pointer;
        display: flex;
        background-color:  #F3FCF7A6;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 110px;
        padding: 8px ;
        box-sizing: border-box;
        gap: 8px;
        margin-bottom: 32px;
      }
      .signup-form > form {
        margin-left: 32px;
        margin-right: 32px;
    }
    .to-terms-and-conditions-container{
        /* border:1px solid black; */
        margin-left:32px;
        margin-right:32px;
        margin-top:32px;
        margin-bottom:32px;
        line-height: 24px;
    }
  }

 