.access-denied-container{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);

	& > p {
		margin-top: 32px;
	}

	& > p:nth-last-of-type(1){
		margin-top: 16px;
		margin-bottom: 32px;
	}

	& > button{
		width:107px;
	}
}

.access-denied-container.bordered-container{
	border: 1px solid #f5f7f6;
	width: calc(100vw - 300px);
	height: 392px;
	border-radius: 6px;
}

.lock-icon-container{
	height: 64px;
	width: 64px;
	border-radius: 50%;
	border: 1.5px solid #F4F4F5;
	padding: 15px 16px;
}