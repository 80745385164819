.secSeparator {
    margin: 0px 0 20px;
}
.tabContent{
    padding: 32px 24px;
}

.descriptionTextarea{
    margin-top: 6px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #e1e5e2;
    height: 128px;
    color: #464d48;
}

.descriptionTextarea::placeholder{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #A0A0AB;
}

.descriptionTextarea:focus{
    border: 1px solid #34cb65;
}

.categoryName{
    padding: 4px 0;
}

.noSelectionText{
    text-align: center;
    margin: 50px 0;
}

.selectedSecondaryText{
    margin-top: 0;
}

.selectionHeader{
    padding: 12px 16px;
}

.disabled{
    background-color: #f7faf8;
}

.accessTypeContainer{
    border: 1px solid #e1e5e2;
    width: 230px;
    border-radius: 6px;
    padding: 8px 12px;
}

.groupBorder{
    height: 1px;
    background-color: #f4f4f5;
    margin: 8px 16px;
}

.removeIcon{
    visibility: hidden;
}

.selectedRoleUser:hover .removeIcon{
    visibility: visible;
}