
 .overview-container{
   margin-top:60px;
   margin-left: calc(var(--side-nav-width) + 32px);
   margin-right: 20px;
 }
 .dashboard-container {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 24px;
   margin-right: 12px;
 }
 
 .dashboard-daterange-picker-container {
   right: 80px !important;
 }
 
 .dashboard-chart-container:first-child {
   border-radius: 5px 5px 0 0;
 }
 
 .dashboard-chart-container .table-dropdown-container button:disabled {
   color: rgb(170, 170, 170);
 }
 
 /* for extra space under the users table, and also so that there is space below the table-dropdown */
 .dashboard-chart-container:last-child {
   border-radius: 0 0 5px 5px;
 }
 
 .dashboard-container > .title {
   height: 106px;
   padding-top: 32px;
   padding-left: 40px;
 
   font-family: "Inter" !important;
   font-style: normal !important;
   font-weight: 500 !important;
   border-top: 1px solid #dedede;
   border-bottom: 1px solid #dedede;
 }
 
 .dashboard-container > .call-logs-controls {
   margin-top: 0;
 }
 .dashboard-stats-container{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   flex-basis: 40%;
   gap: 24px;
 }
 .dashboard-overview-stats-container {
   /* margin-right: 40px; */
   margin-left: 0px;
   display: flex;
   flex-direction: row;
   border: 1.5px solid #dddddd;
   border-radius: 6px;
   justify-content: space-between;
   border-collapse: collapse;
   border-left-width: 1px;
   border-right-width: 1px;
   width: 100%;
   /* min-width: 900px; */
 }
 
 .dashboard-overview-stats {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   flex-basis: 50%;
   justify-content: center;
   text-align: center;
   padding: 10px 8px;
   height: calc(((100vw - var(--side-nav-width) + 22px) * 0.11) - 13px - 0.1vw);
   /* height: 120px; */
 }
 /* .dashboard-overview-stats .title {
   font-size: 18px;
   line-height: 23px;
 } */
 .dashboard-overview-stats .medium-font {
   font-size: 24px;
   line-height: 28px;
   font-weight: 500;
 }
 .dashboard-overview-stats:last-child:after {
   border-right: none;
 }
 
 .dashboard-overview-stats:after {
   content: "";
   position: absolute;
   width: 1px;
   height: calc(100% - 24px);
   left: 100%;
   border-right: 1px solid #ebebeb;
 }
 
 .dashboard-charts-container {
   height: 100%;
   position: relative;
 }
 
 .dashboard-linechart-container{
   /* height: 435px; */
   border: 1px solid #e1e5e2;
   border-radius: 6px;
   flex-basis: 60%;
   padding-bottom: 32px;
   padding-top: 0;
   min-height: 378px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   gap: 32px;
 }
 .legend-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 37px;
    width: 100%;
 }
 .legend-container .legend{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11.75px;
 }
 .legend-container .legend > div{
    width: 15px;
    height: 15px;
    border-radius: 2px;
 }
 .legend .legend-box-1{
  background-color: #818bf0;
 }
 .legend .legend-box-2{
  background-color: #3ecf8e;
 }
 .legend .legend-box-3{
  background-color: #db78dd;
 }
 .dashboard-linechart-container canvas{
  /* flex-basis: 70% !important; */
 }
 .dashboard-chart-container {
   position: relative;
   height: 100%;
 }
 
 /* LINE chart */
 .custom-toolbar {
   width: 200px;
   height: 32px;
   top: 205px;
   right: -20px;
   position: absolute;
 }
 
 /* table styles*/
 /* table container */
 .dashboard-chart-container:nth-child(2) {
   margin-top: 32px;
 }
 
 /* add column styles */
 .dashboard-chart-container > form {
   position: absolute;
   top: 0;
   left: 80px;
   margin-top: 16px;
   z-index: 2;
 }
 
 .dashboard-chart-container > form > label {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
 }
 
 .dashboard-chart-container > form > label > input {
   margin-top: 10px;
   border: 1px solid #cdcdcd;
   padding: 5px 8px 5px 8px;
   border-radius: 4px;
 }
 
 .dashboard-chart-container {
   margin-left: -40px;
 }
 
 .csv-link {
   left: 50%;
   transform: translate(-50%);
   display: none;
 }
 /* -- add column styles end  */
 
 /* NOTE: overriding table styles */
 svg.main-svg > .infolayer > g.g-gtitle > text.gtitle {
   font-weight: 500 !important;
   font-family: "Inter" !important;
   font-style: normal;
 }
 
 .table #header rect.cell-rect {
   cursor: move;
 }
 
 .table g.column-cell g.cell-text-holder text.cell-text {
   color: green !important;
   transform: translateY(5px);
 }
 
 .table #header g.cell-text-holder text.cell-text {
   cursor: pointer !important;
   transform: translateY(0px);
 }
 
 .table-control-view > .scrollbar-kit {
   display: none;
 }
 
 .dashboard-chart-container:first-child {
   border: 1px solid #dddddd;
   overflow: hidden;
   margin-left: 0px;
   padding-left: 40px;
 }
 
 .dashboard-chart-container:nth-last-of-type(1) {
   margin-left: 0px;
   overflow: hidden;
   /* padding-right: 10px; */
   flex-basis: 55%;
 }
 /* NOTE: plotlyJS style @overrides end */
 
 .table-dropdown-container {
   min-height: 150px;
   width: 200px;
   position: absolute;
   top: 40px;
   z-index: 5;
   transition: all 0.3s ease-out;
   background: #fbfbfb;
   display: flex;
   flex-direction: column;
   user-select: none;
   border-radius: 4px;
   border: 1px solid #c5c5c5;
   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
   overflow: hidden;
 }
 
 .table-dropdown-item {
   transition: all 0.3s ease-out;
   display: flex;
   align-items: center;
   margin: 4px 1px 4px 1px;
   padding: 12px;
   cursor: pointer;
   border-radius: 4px;
 }
 
 .table-dropdown-item-del {
   background: #fbfbfb;
   width: 100%;
 }
 
 .table-dropdown-container > .table-dropdown-item-del:disabled span {
   color: rgb(170, 170, 170);
 }
 
 .table-dropdown-item-del:disabled:hover {
   background: transparent;
 }
 
 .table-dropdown-sep {
   margin-top: 6px;
   margin-bottom: 6px;
   height: 1px;
   border-top: 1px solid #dddddd;
   width: 100%;
 }
 
 .table-dropdown-item:hover {
   background-color: #e2f7e9;
 }
 
 .table-dropdown-item-icon {
   display: inline-block;
   width: 20px;
   margin-right: 8px;
 }
 
 /* table add column */
 .table-add-column-container {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: 556px;
   width: 504px;
   z-index: 9;
   background: #ffffff;
   border: 1px solid #f4f4f4;
   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   overflow-y: scroll;
   overflow-x: hidden;
 }
 
 .table-add-column-container::-webkit-scrollbar {
   display: none;
 }
 
 .table-add-column-container > .header {
   background: #e7e7e7;
   padding: 14px 32px 14px 32px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-radius: 10px 8px 0 0;
   width: 100.2%;
   position: sticky;
   top: 0;
   left: 0;
 }
 
 .table-add-column-container > .header > img {
   width: 26px;
   cursor: pointer;
 }
 
 .add-column-body {
   padding: 40px 32px 24px 32px;
 }
 
 /* except the first one */
 .add-column-body > .item:nth-child(n + 2) {
   margin-top: 24px;
 }
 
 .add-column-body > .item:nth-of-type(2) {
   margin-bottom: 32px;
 }
 
 .add-column-sep {
   width: 100%;
   height: 1px;
   border-top: 1px solid #e7e7e7;
   margin-top: 8px;
   margin-bottom: 8px;
 }
 
 .add-column-body > .item > label {
   display: flex;
   flex-direction: column;
 }
 
 .add-column-body > .item:last-child > .duration-filter {
   flex-direction: row;
   align-items: center;
 }
 
 .add-column-body > .item > label > *:last-child {
   background: #ffffff;
   border: 1px solid #dedede;
   border-radius: 3px;
   margin-top: 10px;
   padding: 9px 12px 9px 12px;
   outline: none;
 }
 
 .add-column-body > .item:last-child > label > *:last-child {
   border: none;
 }
 
 .add-column-body > .item:last-child > label > select {
   background: #ffffff;
   border: 1px solid #dedede !important;
   border-radius: 3px;
   margin-top: 10px;
   height: 40px;
   width: 100%;
   outline: none;
 }
 
 .duration-time-fields {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
 }
 
 .duration-time-fields > input[type="number"] {
   border: 1px solid #dedede;
   border-radius: 3px;
   padding: 9px 12px 9px 12px;
   height: 40px;
   width: 90px;
 }
 
 /* #editing-view-port{
     border:1px solid red;
     width:100px;
     height:100px;
 } */
 
 .duration-time-fields > input[type="number"]::-webkit-inner-spin-button {
   display: none;
   display: block;
   opacity: 1 !important;
 }
 
 .duration-time-fields > span {
   margin-left: 10px;
   margin-right: 10px;
 }
 
 .add-column-button-container {
   box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
   border-radius: 0 0 10px 10px;
   display: flex;
   justify-content: flex-end;
   padding-top: 16px;
   padding-bottom: 24px;
   position: sticky;
   left: 0;
   bottom: 0;
   width: 100%;
   z-index: 11;
   background: #ffffff;
 }
 
 .add-column-button-container > input:nth-child(1) {
   margin-right: 16px;
 }
 
 .add-column-button-container > input:nth-child(2) {
   margin-right: 32px;
 }
 
 /* overlay for add column */
 .dashboard-container .table-add-column-overlay {
   left: -219px;
   display: block;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 3;
 
   background: rgba(0, 0, 0, 0.4);
   width: 200vw;
   height: 100vh;
 }
 
 /* NOTE: table toolbar */
 .table-toolbar {
   height: 60px;
   margin-left: 0px;
   margin-right: 40px;
   transform: translateY(99.5px);
   z-index: 3;
   position: relative;
   background: #f9f9f9;
   border: 1px solid #dddddd;
   border-bottom: none;
   border-radius: 5px 5px 0px 0px;
 
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding-left: 12px;
   padding-right: 12px;
 }
 
 .dashboard-container .table-toolbar {
   padding: 12px 16px 12px 16px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
 }
 
 .dashboard-container .table-toolbar .search-container {
   position: relative;
 }
 
 .dashboard-container .table-toolbar .search-container:after {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   border-radius: 2px;
   background: #ffffff;
   z-index: -1;
 }
 
 .dashboard-container .table-toolbar .search-container .search {
   background: url("../../assets/SearchIcon.svg") no-repeat 8px 9px;
   width: 300px;
   height: 37px;
   padding-left: 32px;
   border: 1px solid #dedede;
   border-radius: 2px;
   color: #464d48;
 }
 
 .table-toolbar-right-buttons {
   display: flex;
   flex-direction: row;
   align-items: center;
 }
 
 .table-toolbar-right-buttons > button:first-child {
   /* padding: 8px 24px 8px 24px; */
   height: 38px;
   padding-left: 12px;
   padding-right: 12px;
   background: #ffffff;
   border: 1px solid #dddddd;
   border-radius: 4px;
   margin-right: 16px;
 }
 
 .table-toolbar-right-buttons > button:last-child {
   background: url("../../assets/dashboard-table-toolbar-download-icon.svg")
     no-repeat -1px -1px;
   width: 39px;
   height: 35px;
   border: 1px solid #dddddd;
   outline: none;
   border-radius: 3px;
   margin-right: 12px;
 }
 
 /* plotly-js linechart overrides */
 .dashboard-chart-container .plotly-line-chart {
   left: -42px !important;
 }
 
 .gtable-cell {
   border-right: 1px solid #e1e5e2;
 }
 .dashboard-gtable {
   flex: 1 !important;
 
   height: calc(65%) !important;
 
   overflow: hidden !important;
   /* overflow-y:scroll !important ; */
 }
 
 .dashboard-gtable .gtable-container {
   height: 105% !important;
 }
 .dashboard-gtable .dashboard-header {
    padding-left: 10px;
   border-right: 1px solid #e1e5e2;
 }
 
 .dashboard-gtable .MuiDataGrid-cell {
    padding-left: 0px;
 }
 
 .dashboard-gtable .MuiDataGrid-columnHeader {
    padding-left: 24px !important;
 }
 .status-container{
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 6px;
   padding-left: 10px;
 }
 .status-circle{
   width: 10px;
   height: 10px;
   border-radius: 8px;
   border: 1.5px solid #fff;
   background-color: #e0544c;
   display: inline-block;
 }
 .status-circle.Available{
   background-color: #34cb65;
 }
 .status-circle.Away{
   background-color: #c6ccc8;
 }
 .useWeb{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

 }
 .useWebDis{
  width: 248px;
  height: 88px;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 22.4px;
text-align: center;
margin-top: 10px;
margin-left: 10px;
 }
 .useweblogo{
  height:50px;
    
 }
 @media  screen and (max-width: 1400px) {
  .dashboard-overview-stats{
    height: 110px;
  }
  /* .dashboard-linechart-container canvas{
    height: 348px !important;
    width: 100% !important;
  } */
}
@media screen and (min-width: 1800px){
  .dashboard-overview-stats{
    height: calc(((100vw - var(--side-nav-width) + 20px) * 0.1067) - 18px);
  }
  .dashboard-stats-container{
    gap: 32px;
  }
}
@media screen and (min-width: 2100px){
  .dashboard-overview-stats{
    height: calc(((100vw - var(--side-nav-width) + 20px) * 0.1067) - 26px);
  }
  .dashboard-stats-container{
    gap: 40.5px;
  }
}
@media screen and (min-width: 2250px){
  .dashboard-overview-stats{
    gap: 10px;
  }
  .dashboard-overview-stats p:first-child{
    font-size: 1.2rem;
  }
  .dashboard-overview-stats p:nth-child(2){
    font-size: 28px;
  }
} 
