.teams-view-table-header-team-name {
	padding-left: 24px !important;
}

.MuiDataGrid-columnHeader[data-field="teamViewRole"] {
	padding-left: 24px !important;
}

.secondary-text{
	margin-top: 2px;
}
 
.pNoOverflow{
  max-width: 280px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.screen-container-teams{
	padding-top: 8px;
}

.secondary-text{
	margin-top: 2px;
}