.container {
  border: 1px solid var(--grey-200);
  border-radius: 6px;
  padding-bottom: 20px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--grey-200);
  margin-bottom: 12px;
  div {
    border: 1px solid var(--grey-200);
    display: flex;
    align-items: center;
    gap: 10.5px;
    padding: 8px 12px;
    border-radius: 6px;
    input {
      border: none;
      color: var(--grey-700);
    }
    input::placeholder {
      color: var(--grey-400);
    }
  }
  button {
    border: 1px solid var(--grey-200);
    border-radius: 6px;
    padding: 10px;
    background: transparent;
  }
}
.dialogue {
  display: flex;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 0 8px;
  span {
    color: var(--grey-700);
    flex-shrink: 0;
  }
  h6 {
    margin: 0 8px 0 12px;
    color: var(--grey-800);
    flex-shrink: 0;
  }
  p {
    color: var(--grey-700);
  }
}
.dialogue:hover {
  background-color: var(--grey-50);
  cursor: pointer;
}
