.gtable-container {
  width: 100%;
  border-style: solid;
  border-width: 0.07px;
  border-width: 0px;
  border-radius: 6px;
  height: 100%;
  margin-bottom: 40px;
}

.gtable-container .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e1e5e2;
  border-radius: 6px 6px 0 0;
  border-bottom-width: 0px;
}

.gtable-container .filters {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  margin-bottom: 16px;
}
.gtable-container .filter {
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  width: fit-content;
  padding: 12px 10px 12px 12px;
  border-radius: 4px;
  margin-right: 12px;
}

.gtable-container .filter > img {
  margin-left: 6px;
}

.gtable-container .filter:nth-last-of-type(1) {
  margin-right: none;
}

.gtable-container :is(.right-header, .left-header) {
  display: flex;
  flex-direction: row;
  /* padding-right: 24px; */
  align-items: center;
  /* padding-top: 16px; */
  /* padding-left: 24px; */
  padding-left: 0px;
  padding-top: 16px;
  margin-bottom: 16px;
}

.gtable-container
  :is(.right-header, .left-header)
  :is(.right-header-item, .left-header-item) {
  cursor: pointer;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.gtable-container
  :is(.right-header, .left-header)
  :is(.right-header-item, .left-header-item):nth-last-of-type(1) {
  margin-right: none;
}

.gtable-container :is(.right-header, .left-header) .search {
  width: 320px;
  min-width: 250px;
  display: flex;
  justify-content: stretch;
}

/* search input  */
.gtable-container :is(.right-header, .left-header) .search > input {
  border: none;
  outline: none;
  margin-left: 6px;
  /* margin-right: 12px; */
  padding: 0;
  width: 100%;
}

.gtable-container
  :is(.right-header, .left-header)
  .search
  > input::placeholder {
  /* color: Neutral Colors/Grey - 400 */
  color: #c6ccc8;
}

.gtable-container
  :is(.right-header, .left-header)
  .search
  > input[type="search" i]::-webkit-search-cancel-button {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  background-image: url(../../../assets/close.svg);
  background-size: 14px 14px;
}

/* datagrid styles screen-wise*/
/* can be moved to specifc pages. ex: .users-table-row can be moved to Users page */

.users-table-row {
  background-color: #ffffff;
  transition: all 0.3s ease-out;
  /* Neutral Colors/Grey - 200 */
  border-bottom: 1px solid #f5f7f6;
}
.users-table-row:hover {
  /* Neutral Colors/Grey - 100 */
  background-color: #f7faf8;
}

.teams-table-row {
  text-decoration: underline;
  cursor: pointer;
}

/* team view styles */

/* .teams-view-table-cell-user-name {
	padding-left: 24px;
} */
.teams-view-table-header-team-name {
  padding-left: 24px !important;
}

.teams-table-header-team-name {
  padding-left: 24px !important;
}

.user-table-header-user-name {
  padding-left: 24px !important;
}

/*survey header styles*/
.survey-table-header{
  padding-left: 24px !important;
}

/* styles below this line will be revised */
.gtable {
  min-width: calc(100vw - 285px);
  position: relative;
}

.gtable thead tr {
  min-width: calc(100vw - 285px);
  text-align: left;
}
