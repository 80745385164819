.autoRechargeForm{
    display: flex;
    flex-direction: column;
    width: 320px;
    margin: 0 auto;
    gap: 24px;
}
.autoRechargeForm label{
    display: flex;
    flex-direction: column;
}
.autoRechargeForm label > span{
    margin-bottom: 4px;
}
.autoRechargeForm label input{
    width: 320px;
}