.side-nav-ul {
  border: 1px solid #dedede;
  /* height: calc(100vh - var(--top-nav-height) + 8px - var(--credit-container-height)); */
  height: calc(100vh - var(--top-nav-height) + 8px);
  width: var(--side-nav-width);
  list-style-type: none;
  top: var(--top-nav-height);
  /* bottom:var(--credit-container-height); */
  position: fixed;
  padding-top: 16px;
  z-index: 3;
  background: white;
  border-top: none;
  margin-top: 2px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.side-nav-ul::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}


.side-nav-li {
  margin: 0px 12px 8px 12px;
  transition: all 0.3s ease-out;
  border-radius: 4px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-nav-li:hover {
  /* Interface Colors/Gray - 100 */
  background: #f7faf8 !important;
}

.collapse-nav-container {
  padding: 12px;
  padding-right: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.side-nav-link {
  padding-right: 12px;
  padding-left: 12px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;
  color: unset; 
}

.side-nav-link:hover,.active-link {
  color: unset;
}

.side-nav-link > *:nth-child(1) {
  margin-right: 8px;
}

.child-link {
  padding-left: 44px;
}

.side-nav-sep {
  margin-top: 2em;
  height: 1px;
  width: 100%;
  background: #f3f3f3;
}

.active-link {
  background-color: #f0fcf4;
}

.active-link p {
    color: #2eb358;
}
