.container{
    position: relative;
    height: 55vh ;
    max-height: 55vh;
    overflow-y: hidden;
    overflow-x: hidden;
}
.overlay{
    background-color: rgba(0,0,0,0.25);
    /* backdrop-filter: blur(3px); */
    transition: all 0.4s;
    height: 55vh;
    position: absolute;
    width: 488px;
}
.date{
border-radius: 6px;
padding: 8px 10px;
background:  #F7FAF8;
width: max-content;
margin:16px auto;
}
.dateContainer{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.msgContainer{
    min-height: calc(55vh - 150px);
    max-height: calc(55vh - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.OUTBOUND_msg{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin:6px; */
    margin-right: 19px;
}

.INBOUND_msg{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin:6px; */
    margin-left: 24px;
    width:calc(100% - 24px);
}
.OUTBOUND_msg > .content, .INBOUND_msg > .content{
    padding: 8px 12px;
    border: 1px solid #e1e5e2;
    border-radius: 6px;
    gap: 6px;
    width: max-content;
    max-width: 310px;
} 
.OUTBOUND_msg > .content > p, .INBOUND_msg > .content > p{
    word-wrap: break-word;
}
.OUTBOUND_msg > .viewedMsg, .INBOUND_msg > .viewedMsg{
    border: 1px solid #34cb65;
}
.OUTBOUND_msg > .content{
    background-color: #f0fcf4;
}
.time{
    margin: 0 4px;
}
.listAndFormContainer{
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.form{

display: flex;
width: 480px;
padding: 20px 24px;
justify-content: flex-end;
align-items: flex-end;
gap: 12px;
border-radius: 0px 0px 10px 10px;
border-top: 1px solid #E1E5E2;
}
.templateListContainer ul{
    transition: all 0.4s;
    max-height: 190px;
    overflow-y: scroll;
    margin-bottom: 0;
}
.templateListContainer ul::-webkit-scrollbar, .vnDropdown ul::-webkit-scrollbar{
    width: 12px;
    background-color: #f7faf8;
    border-radius: 8px;
}
.templateListContainer ul::-webkit-scrollbar-thumb, .vnDropdown ul::-webkit-scrollbar-thumb{
    background-color: #c6ccc8;
    border-radius: 8px;
}
.listHeader{
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #e1e5e2;
}
.closeIcon{
    float: right;
    cursor: pointer;
}
.templateListContainer li{
    padding: 12px 24px;
    border-bottom: 1px solid #f5f7f6;
    cursor: pointer;
}
.templateListContainer li:hover{
    background-color: #F7FAF8;
}
.templateListContainer li:last-child{
    border-bottom: none;
}
.retryBtn{
    text-decoration: underline;
    color: #2eb258;
}
.retryBtn:hover{
    color: #28994b;
    cursor: pointer;
}
.failedMsg{
    margin-right: 19px;
    display: flex;
    gap: 4px;
}
.msgAndErrContainer{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.vnForm{
    border-top: 1px solid #e1e5e2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 24px;
}
.vnForm .chip{
    border: 1px solid #e1e5e2;
    padding: 6px 8px 6px 10px;
    margin-left: 12px;
    border-radius: 4px;
    display: flex;
    gap: 6px;
}
.vnFrom .closeIcon{
    cursor: pointer;
}
.vnDropdown{
    position:absolute;
    /* top:calc(50vh + 158px); */
    bottom: calc(22.5vh - 90px);
    left:calc(50vw - 180px);
    background-color:#fff;
    z-index:1500;
    opacity:1;
    box-shadow: 0px 4px 8px 8px rgba(95, 102, 97, 0.04);
    border-radius: 6px;
    width: 400px;
}
.vnDropdown ul{
    margin: 0;
	padding-left: 0;
	list-style-type: none;
	border-top: 1px solid #e1e5e2;
	text-align: left;
  
	font-family: "Inter", sans-serif;
	height: fit-content;
	max-height: 140px;
	overflow-y: scroll;
}
.vnDropdown ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    
  }
  .vnDropdown ul li:hover {
	background-color: #f7faf8;
  }
  .vnDropdown ul li div{
    width: 384px;
  }
  .vnDropdown ul li div p {
	max-width: 370px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
  }
  .vnDropdownContainer > p{
    color: #c6ccc8;
    margin-left: 12px;
    cursor: pointer;
  }