.gheader-container {
  margin-bottom: 24px;
}

.gheader-container .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gheader-container > .bottom-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* .gheader-container > .gsep{
	margin-top: 32px;
	margin-bottom: 32px;
    transform:translateX(-40px);
    width:100vw;
	border-top: 1px solid #E1E6E2;
} */

.gheader-container > .bottom-header-container > .bottom-right-header {
  display: flex;
}
