.tab-selection {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 1px solid #e1e6e2;
  padding: 0 32px;
  margin: 24px -32px 56px -32px;
}

.tab-selection .tab-label {
  margin: 0;
  padding: 12px 24px;
  border: 1px solid #e1e6e2;
  border-bottom-width: 0;
  color: #5f6661;
  cursor: pointer;
  background-color: #f7faf8;
}

.tab-selection .selected-tab-label {
  background-color: white;
  padding-top: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #464d48;
}

.virtual-numbers-form input {
  margin-top: 4px;
}

.virtual-numbers-form input[type="text"]:disabled {
  background: #f5f7f6;
}

.listen-btn {
  display: flex;
  margin-top: 12px;
  align-items: center;
  cursor: pointer;
}
.listen-btn p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #28994b;
  margin-left: 5px;
}

.tts-input {
  width: 400px;
  height: 120px;
  padding: 8px 12px;
  margin-top: 10px;
  border: 1px solid #e1e6e2;
  border-radius: 4px;
}

.connect-to-box {
  border: 1px solid #e1e6e2;
  border-radius: 6px;
  margin-top: 12px;
}

.connect-to-box .box-title {
  background: #f5f7f6;
  color: #5f6661;
  padding: 16px 24px;
  border-bottom: 1px solid #e1e6e2;
}

.connect-to-box .options {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
}

.draggable-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e1e6e2;
  border-radius: 0px;
  padding: 16px 0;
}

.draggable-user p {
  margin: 0;
}

.call-dist-options {
  padding: 10px 6px;
  /* width: 380px; */
}

.call-dist-options p {
  margin: 0;
}
