.warningContainer{
    background-color: #fefbe8;
    display: flex;
    border-radius: 6px;
    padding: 12px 16px;
    color: #464c48;
}
.warningContainer p{
    margin-left: 12px;
    line-height: 180%;
}
.container >p:first-child{
    margin-bottom: 10px;
}
.container > p:nth-child(2){
    margin:0
}
.container form{
    margin: 32px 0;
    width: 320px;
}