.new-contact-list-form > label {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
}

.upload-file-label {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.new-contact-list-form input[type="text"] {
  margin-top: 10px;
  border: 1px solid #dedede;
  border-radius: 3px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  cursor: text;
  outline: none;
  font-weight: 100;
}

.upload-file-label input {
  border: 1px dashed #dedede;
  height: 140px;
  display: flex;
  padding-top: 60px;
  padding-left: 135px;
  margin-top: 10px;
  opacity: 0;
  cursor: pointer;
}

.upload-file-label > button {
  position: absolute;
}

.upload-file-label > span {
  position: absolute;
  bottom: 10px;
  display: inline-block;
}
.upload-file-label > img {
  position: absolute;
}

.upload-file-label {
  margin-bottom: 24px;
  position: relative;
  margin-top: 10px;
}

/* FILE UPLOAD BOX */

.upload-file-label .no-file {
  position: absolute;
  border: 2px dashed #dedede;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-file-label .no-file a {
  text-decoration: underline;
  font-size: 16px;
  line-height: 16px;
}

.upload-file-label .has-file {
  border: 1px solid #e1e5e2;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 24px;
}

.upload-file-label .has-file p {
  margin: 0;
}

.contact-lists-empty .gsep {
  margin-top: 32px;
  margin-bottom: 32px;
  transform: translateX(-40px);
  width: 100vw;
  border-top: 1px solid #e1e6e2;
}


.contact-lists-view-table-header-list-name {
	padding-left: 20px !important;
}
