.contactIcons{
    padding: 8px;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
}
.contactIcons:hover{
	background-color: #f5f7f6;
    border-radius: 4px;
}

.contactIcons:hover svg path{
    fill: #23b258 ;
    stroke: #23b258 ;
    cursor: pointer;
}
.messageDisabled:hover svg path{
    stroke: #c6ccc8;
    fill: none;
    cursor:default;
}
.unreadCircle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e0544c;
    margin-right: 5px;
    margin-top: 4px;
}